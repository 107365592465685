.add-document-details h1 {
  margin-bottom: var(--spacing-l);
}

.add-document-details p {
  margin: 0px;
}

.add-document-details__submit-button {
  margin-bottom: var(--spacing-s);
}
