.register-or-reset__body {
  display: flex;
  flex-direction: column;
}

.registration__body h1 {
  font-size: var(--fs-xl);
  font-weight: var(--fw-regular);
  line-height: var(--lh-xl);
  margin-bottom: 24px;
}

.register-or-reset__body h1 span {
  font-weight: var(--fw-bold);
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
}

.register-or-reset__body h1 p {
  overflow-wrap: break-word;
}

.register-or-reset__enter-password {
  margin-bottom: 30px;
}

.register-or-reset__create-password label {
  font-size: var(--fs-base);
  font-style: normal;
  line-height: var(--lh-base);
}

.register-or-reset__create-password-requirements ul {
  margin-bottom: 8px;
}
.register-or-reset__create-password .text-input .input-field {
  border-radius: var(--border-radius-s);
}
.register-or-reset__create-password .outer-wrapper {
  border-width: 2px;
}

.additional-assistance-input .input-wrapper textarea {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.register-or-reset__create-password-requirements ul span,
.register-or-reset__create-password-requirements p {
  font-weight: var(--fw-regular);
  font-size: var(--fs-s);
  font-style: normal;
  line-height: var(--lh-s);
}

.register-or-reset__create-password-password-must {
  margin-top: 5px;
  margin-bottom: 10px;
}

.register-or-reset__create-password-input,
.register-or-reset__confirm-password-input {
  margin-bottom: 0px;
  margin-top: 0px;
  max-width: 600px;
}

.register-or-reset__create-password-input .field-error,
.register-or-reset__create-password-input .outer-wrapper.error,
.register-or-reset__confirm-password-input .field-error,
.register-or-reset__confirm-password-input .outer-wrapper.error {
  margin-top: 0px;
  margin-bottom: 0px;
}

.register-or-reset__create-password-input .field-error label,
.register-or-reset__confirm-password-input .field-error label {
  margin-left: 0px;
  font-weight: var(--fw-regular);
}

.register-or-reset__confirm-password {
  display: flex;
  flex-direction: column;
}

.register-or-reset__confirm-password-label {
  margin-top: 22px;
  margin-bottom: 0px;
}

.requirement-item-list-style--default {
  list-style-type: disc;
}

.requirement-item-list-style--success,
.requirement-item-list-style--error {
  display: flex;
  align-items: center;
  margin-left: -38px;
}

.requirement-item-list-style--success span,
.requirement-item-list-style--error span {
  margin-left: 10px;
}

.register-or-reset__checkboxes {
  margin-top: 30px;
}

.register-or-reset__checkboxes-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.register-or-reset__checkboxes-item .checkbox {
  width: 28px;
  height: 28px;
  margin-top: 2px;
}

.register-or-reset__checkboxes-item .checkbox input {
  border: 2px solid var(--color-universal-secondary-a);
}

.register-or-reset__checkboxes-item .checkbox input:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.register-or-reset__checkboxes-item label {
  margin-left: 20px;
  font-size: var(--fs-base);
  font-style: normal;
  font-weight: var(--fw-medium);
  line-height: var(--lh-base);
}

.register-or-reset__checkboxes hr {
  background: var(--divider-color-context-background-primary);
  margin-top: 0;
  margin-bottom: 30px;
}

.register-or-reset__checkboxes-item label a {
  color: var(--link-color-action-text-primary);
  font-size: var(--fs-base);
  font-style: normal;
  font-weight: var(--fw-medium);
  line-height: var(--lh-base);
  text-decoration: underline;
}

.register-or-reset__body .register-or-reset__create-button button {
  color: var(--button-color-action-text-primary);
  width: 252px;
  background-color: var(--button-color-action-background-primary);
}
.register-or-reset__body .register-or-reset__create-button button:focus,
.register-or-reset__body .register-or-reset__reset-button button:focus {
  background-color: var(--button-color-action-background-primary-hover);
}

.register-or-reset__body .register-or-reset__reset-button button {
  color: var(--button-color-action-text-primary);
  width: 330px;
  background-color: var(--button-color-action-background-primary);
}

.register-or-reset__create-button button span,
.register-or-reset__reset-button button span {
  font-size: var(--fs-base);
}

@media only screen and (max-width: 375px) {
  .requirement-item-list-style--success,
  .requirement-item-list-style--error {
    align-items: flex-start;
  }
}

/* this is part of the library we use for the phone input */
.PhoneInput {
  margin-top: 5px;
  display: flex;
  align-items: center;

  input {
    flex: none;
    height: 52px;
    border-radius: 5px;
    border: 1px solid var(--form-color-interaction-border-primary);
    padding-left: 20px;
  }
  input:focus {
    border-color: var(--form-color-interaction-border-primary-focus);
  }

  input:focus-within {
    outline: 1px solid var(--form-color-interaction-border-primary-focus);
    border-radius: var(--border-radius-s);
    color: var(--form-color-interaction-text-primary-focus);
  }

  input:hover {
    background: var(--form-color-interaction-background-primary-hover);
  }
}

.text-input .outer-wrapper {
  height: 52px;
}
