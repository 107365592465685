.nav-menu-dropdown {
  position: absolute;
  background-color: var(--navbar-color-interaction-background-primary);
  color: var(--color-universal-secondary-b);
  top: 0;
  left: 0;
  z-index: var(--z-index-4);
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
  border-left: 1px solid var(--color-universal-secondary-d);
  border-right: 1px solid var(--color-universal-secondary-d);
}

.main-menu .nav-menu-dropdown {
  top: 44px;
}

.Sharing .nav-menu-dropdown {
  left: -15px;
}

.disabled:hover {
  font-weight: var(--fw-regular) !important;
}

.dropDownText .icon-box {
  display: inline-block;
  width: var(--icon-sizing-xs);
  margin-left: 15px;
  margin-right: 15px;
}

.dropDownText .icon-box svg {
  width: 100%;
  height: auto;
}

.nav-menu-item {
  font-weight: var(--fw-regular);
  font-size: var(--fs-s);
  width: 270px;
  cursor: pointer;
  margin-left: -2rem;
  border-bottom: 1px solid var(--color-universal-secondary-d);
}
.nav-menu-item:hover {
  background-color: var(--component-color-interaction-background-primary-hover);
  font-weight: var(--fw-bold);
}

.nav-menu-item .hidden {
  height: 0;
  overflow: hidden;
}
.nav-menu-item .open {
  height: auto;
}
.nav-menu-item .dropDownText {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
}

.nav-menu-item.level0.expanded {
  background-color: var(--color-universal-secondary-b);
  color: var(--color-universal-primary-background);
  border-bottom: none;
  border-right: none;
}

.nav-menu-item.level0:not(.expanded) .dropDownText:hover {
  background-color: var(--component-color-interaction-background-primary-hover);
}

.nav-menu-item.level0.expanded svg {
  stroke: var(--color-universal-primary-background);
}

.nav-menu-item:last-child {
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
}
.nav-menu-item:not(:last-child) .nav-menu-item {
  border-radius: 0;
}

.nav-menu-item.expanded:last-child {
  padding-bottom: 0;
}

.menuitem-box.expanded:last-child .expanded:last-child {
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
}

.nav-menu-item.level0 {
  background-color: var(--navbar-color-interaction-background-primary);
}

.nav-menu-item.level1 {
  background-color: var(--navbar-color-interaction-background-expand);
  color: var(--color-universal-secondary-a);
}

.nav-menu-item.level1:hover {
  background-color: var(--component-color-interaction-background-primary-hover);
}
.menuitem-box:last-child .nav-menu-item.level0:last-child {
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
}

.nav-menu-item.level1 .dropDownText {
  margin-bottom: 0;
}
.nav-menu-item .click-me {
  font-size: var(--fs-s);
}

.onex-dropdown {
  left: 4px !important;
  top: 28px !important;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-universal-secondary-d);
}

@media (max-width: 1229px) {
  .nav-menu-item {
    background-color: var(--color-universal-natural-grey);
    color: var(--color-universal-natural-black);
    width: inherit;
  }
  .nav-menu-dropdown {
    border-right: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .nav-menu-item.level0,
  .nav-menu-item.level1,
  .nav-menu-item.level2,
  .nav-menu-item.level3 {
    background-color: var(--navbar-color-interaction-background-expand);
  }

  .nav-menu-dropdown {
    border: none;
    border-top: 1px solid var(--color-universal-secondary-d);
  }

  .nav-menu-item:last-child {
    border-bottom: none;
    border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
  }
  .nav-menu-item .dropDownText {
    padding: 9px 25px 9px 25px;
    height: unset;
  }
  .menuitem-wrapper > ul {
    margin-bottom: 0;
  }

  .one-x .main-menu .nav-menu-dropdown {
    top: 20px;
    left: -110px;
    border: 1px solid var(--color-universal-secondary-d);
    width: 270px;
  }

  .one-x .main-menu .Sharing .nav-menu-dropdown {
    left: -30px;
    width: 150px;
  }

  .Sharing .nav-menu-item .dropDownText {
    padding: 9px 25px 9px 25px;
    height: unset;
  }

  .one-x .nav-menu-dropdown {
    position: absolute;
    margin-top: 10px;
  }

  .one-x .nav-menu-item.level0,
  .one-x .nav-menu-item.level1,
  .one-x .nav-menu-item.level2,
  .one-x .nav-menu-item.level3 {
    background-color: var(--navbar-color-interaction-background-primary);
  }
  .Sharing .nav-menu-dropdown {
    left: 0;
  }
}
