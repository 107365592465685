@media only screen and (min-width: 1920px) {
  .forgot-password-confirmation {
    max-width: 800px;
  }
}

.forgot-password-confirmation h1 {
  font-weight: var(--fw-bold);
  font-size: 46px;
  line-height: 59px;
  margin-bottom: var(--spacing-l);
}

.forgot-password-confirmation p {
  font-weight: var(--fw-regular);
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  margin-bottom: var(--spacing-l);
}

.forgot-password-confirmation button {
  padding: 0;
  color: var(--link-color-action-text-primary);
  text-decoration: underline;
}

.forgot-password-confirmation span {
  font-weight: var(--fw-regular);
  font-size: var(--fs-m);
  line-height: var(--lh-m);
}
