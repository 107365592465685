.browser-default-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.browser-default-select-container .browser-default-select-wrapper {
  height: var(--spacing-l);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.browser-default-select-container .select-label {
  margin-bottom: 5px;
  font-weight: var(--fw-medium);
  font-size: var(--fs-s);
}

.browser-default-select-container.required:before {
  content: "* ";
  color: var(--component-color-feedback-danger);
}

.browser-default-select-container .error {
  border: 2px solid var(--component-color-feedback-danger);
}

.browser-default-select-container .open-close-svg {
  position: relative;
  width: var(--icon-sizing-xs);
  height: var(--icon-sizing-xs);
  transform: translate(-30px, 0px);
  pointer-events: none;
}

.browser-default-select-container .open-close-svg svg {
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
  width: var(--icon-sizing-s);
  height: var(--icon-sizing-s);
  display: block;
}

.browser-default-select {
  width: 100%;
  cursor: pointer;
  border-radius: var(--border-radius-s);
  padding: 0px 14px 0px 14px;
  height: 100%;
  text-overflow: ellipsis;
  font-weight: var(--fw-regular);
  font-size: var(--fs-s);
  border: 2px solid var(--form-color-interaction-border-primary);

  /* Hide the default arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.browser-default-select:focus,
.browser-default-select:focus-within {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}
