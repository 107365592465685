.document-details-form {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
}

.add-document-details .outer-wrapper {
  border: 2px solid var(--form-color-interaction-border-primary);
}

.add-document-details .text-input,
.add-document-details .text-input .outer-wrapper {
  margin: 0px;
}

.document-details-form .text-input label,
.document-details-form .browser-default-select-container label {
  font-size: var(--fs-base);
  font-weight: var(--fw-medium);
  color: var(--select-color-interaction-text-tertiary);
}

.document-details-form .text-input .input-field,
.document-details-form .browser-default-select {
  font-size: var(--fs-s);
  font-weight: var(--fw-regular);
  padding: 0px var(--spacing-xx-s);
  color: var(--form-color-interaction-text-primary-focus);
}

.document-details-form .browser-default-select {
  position: relative;
  .add-document-details .open-close-svg {
    position: absolute;
    right: 30px;
  }
}

@media (max-width: 500px) {
  .document-details-form {
    flex-direction: column;
    gap: var(--spacing-l);
  }
}

.document-details-form .browser-default-select-wrapper {
  height: 52px;
}
