.create-subfolder .header {
  padding-bottom: 30px;
}

.create-subfolder .modal-content {
  margin-top: 40px;
}

.create-subfolder hr {
  margin-bottom: 0px;
}

.create-subfolder .guidance-text {
  padding: 0px;
  margin-bottom: 40px;
  font-size: var(--fs-s) !important;
  font-weight: var(--fw-light) !important;
}

.create-subfolder .text-input .text-input-label {
  font-weight: var(--fw-light);
}

.create-subfolder .text-input {
  margin: 0px;
}

.create-subfolder .text-input .input-field:read-only {
  padding-left: 20px !important;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.create-subfolder .outer-wrapper {
  margin: 8px 0px 0px 0px;
}

.create-subfolder .suggestion-zone {
  margin-top: 40px;
}

.suggestionBox {
  border: 1px solid var(--color-universal-secondary-d);
  background-color: var(--color-primary-white);
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 8px 15px;
  font-size: var(--fs-xs);
  font-weight: var(--fw-medium);
  line-height: 20px;
  display: flex;
  align-items: center;
}

.suggestionBox:hover {
  background-color: var(--component-color-interaction-background-primary-hover);
}

.suggestionBox svg {
  margin-right: 10px;
  height: 16px;
  width: 16px;
}
