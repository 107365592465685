.page-wrapper {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);

  .main-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    grid-column: 2/3;

    .search-bar-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: var(--spacing-l);
      padding-bottom: var(--spacing-s);
      gap: var(--spacing-s);

      input {
        border-radius: var(--search-border-radius);
      }
    }
  }
}

.organisations-page-container,
.clients-page-container {
  padding-top: var(--spacing-l);

  @media (max-width: 1480px) {
    padding-top: 0;
  }
}

.page-content {
  flex-grow: 1;

  &.onex {
    width: 100%;
    margin-right: 20px;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 2110px;
      padding: 0px 20px;
      margin-right: 20px;
    }
  }
}

.prevent-click {
  pointer-events: none;
}

// abndn primary user
.main-layout.abrdn-primary-user {
  background: var(--color-universal-natural-white);

  .topBar-abrdn-border {
    background: var(--color-universal-natural-white);
  }
  .literature-and-guides {
    background: var(--page-color-context-background-primary);
  }
  .file-page {
    background: var(--color-universal-natural-white);
  }
}
