.text-input-labels-container {
  display: flex;
  justify-content: space-between;
}

.text-input .text-input-sublabel,
.text-input .text-input-label {
  display: block;
  color: var(--color-universal-secondary-b);
  font-size: var(--fs-s);
  font-weight: var(--fw-medium);
  padding: 0px 0px 4px 0px;
}
.text-input .text-input-sublabel {
  font-weight: var(--fw-light);
}

.text-input .text-input-link {
  font-size: var(--fs-m);
  color: var(--link-color-action-text-primary);
  text-decoration: underline;
}

.text-input .text-input-link:hover {
  color: var(--link-color-action-text-primary-hover);
  text-decoration: none;
}

.text-input .text-input-link:focus {
  transition: none;
  outline: 2px solid var(--text-input-color-action-outline-focus);
  outline-offset: 3px;
}

.text-input-label.required:before {
  content: "* ";
  color: var(--component-color-feedback-danger);
}

.text-input {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0;
  padding: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
}

.text-input .outer-wrapper {
  display: inline-flex;
  flex-direction: row;
  background-color: var(--form-color-interaction-background-primary);
  border: 1px solid var(--form-color-interaction-border-primary);
  border-radius: var(--border-radius-s);
  height: 40px;
  margin: 8px 0px;
}

.text-input .outer-wrapper.error {
  border-color: var(--component-color-feedback-danger);
}

.text-input .inner-wrapper {
  position: relative;
  flex-grow: 1;
  order: 2;
  flex-direction: row;
  display: flex;
}

.text-input .input-field {
  padding: 0px 20px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: 100%;
  border: 0;
  background: none;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  color: var(--form-color-interaction-text-primary);
  -webkit-tap-highlight-color: transparent;
  border-radius: var(--border-radius-s);
}

.input-field:not(:placeholder-shown) {
  color: var(--form-color-interaction-text-primary-focus);
}

.text-input .inner-wrapper:not(:disabled):hover {
  border: 0;
}

.text-input .input-field:read-only {
  border: 1px solid transparent;
  outline: none;
}

.text-input .inner-wrapper:not(:focus-within) {
  background-color: transparent !important;
}

.text-input .outer-wrapper:focus-within {
  border-radius: var(--border-radius-s);
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.text-input .outer-wrapper .inner-wrapper:visited {
  color: var(--form-color-interaction-text-primary-focus);
}

.text-input .outer-wrapper :focus-visible {
  outline: 0;
  border: 0;
}

.text-input .input-field:disabled {
  border: 1px solid var(--form-color-interaction-border-primary);
  border-radius: var(--border-radius-s);
  background-color: var(--component-color-action-background-disabled);
  opacity: 1;
  border: 1px solid transparent;
  outline: none;
}

.text-input .adornment-start {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  order: 1;
  padding: 10px 0 10px 10px;
}

.text-input .adornment-end {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  order: 3;
  padding: 10px 10px 10px 0;
}

textarea.input-field {
  line-height: var(--fs-xxl);
}

.truncate-overflow {
  overflow: hidden;
  position: relative;
  height: calc((2 * var(--lh-m)));
}
.truncate-overflow::before {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
}
.truncate-overflow::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1rem;
  height: 1rem;
  background: var(--color-universal-natural-white);
}

.text-input .crossIcon {
  position: absolute;
  top: 6px;
  right: 5px;
  height: 20px;
  width: 20px;
}

.text-input .crossIcon:hover {
  cursor: pointer;
}

.text-input .crossIcon svg {
  height: 16px;
  width: 16px;
}

.text-input .hide-icon svg {
  width: 20px;
  height: 20px;
}

.text-input .hide-icon {
  margin-right: 10px;
}

.input-field.error {
  outline: 2px solid var(--component-color-feedback-danger);
}

.text-input .outer-wrapper .inner-wrapper.error:focus-within {
  outline: 2px solid transparent !important;
}

/* Hide scroll arrows in number format */
/* Chrome, Safari, Edge, Opera */
.hide-arrows input::-webkit-outer-spin-button,
.hide-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-arrows input[type="number"] {
  -moz-appearance: textfield;
}
