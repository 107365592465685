input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.page-content-search-bar {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  position: relative;
  .search-icon-container {
    position: absolute;
    top: 14px;
    left: 14px;
    svg {
      height: 18px;
      width: 18px;
      stroke: var(--search-color-interaction-text-primary);
    }
  }
  .search-input {
    &:focus {
      outline: 2px solid var(--color-input-outline-focus);
      outline-offset: 3px;
    }
  }
  .search-input.search-input-with-icon {
    padding-left: 40px;
  }
  .search-input-with-button.crossIcon {
    right: 74px;
  }
  .crossIcon {
    position: absolute;
    top: 16px;
    right: 20px;
    height: 24px;
    width: 24px;
    padding: 0;
  }
  svg {
    height: 24px;
    width: 24px;
    fill: var(--search-color-interaction-text-primary-focus);
  }
  .crossIcon:focus-within {
    outline: 2px solid var(--color-input-outline-focus);
    outline-offset: 3px;
  }
}

.page-content-search-bar .search-input {
  padding: 16px 12px;
  height: 60px;
  font-size: var(--fs-base);
  font-weight: var(--fw-regular);
  line-height: var(--lh-m);
  background-color: var(--search-color-interaction-background-primary);
  border: 2px solid var(--search-color-interaction-border-primary);
  color: var(--search-color-interaction-text-primary);
  border-radius: var(--search-border-radius);

  &:focus {
    color: var(--search-color-interaction-text-primary-focus);
    border-color: var(--search-color-interaction-border-primary-focus);
    outline: 2px solid var(--color-input-outline-focus);
    outline-offset: 3px;
  }

  &:hover {
    background-color: var(--search-color-interaction-background-primary-hover);
  }
}

.page-content-search-bar input.search-input::placeholder {
  color: var(--search-color-interaction-text-primary);
}

.page-content-search-bar .search-bar-button {
  width: 60px;
  height: 60px;
  background-color: var(--button-color-action-background-secondary);
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.search-bar-button {
  &:focus-within {
    outline: 2px solid var(--color-input-outline-focus);
    outline-offset: 3px;
  }
}

.page-content-search-bar .search-bar-button svg {
  stroke: var(--search-color-interaction-background-primary);
  margin: 0 auto;
}
