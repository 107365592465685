.panel {
  background-color: var(--panel-color-background-primary);
  padding: var(--spacing-m) 48px;
  border-radius: var(--panel-border-radius-primary);
  box-shadow: var(--panel-shadow-primary);
}

@media (max-width: 375px) {
  .panel {
    padding: var(--spacing-m) var(--spacing-s);
  }
}
