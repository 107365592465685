.sign-in-panel {
  font-size: var(--fs-xl);
  font-weight: var(--fw-regular);
  line-height: var(--lh-xl);
}

.sign-in-panel__form .text-input-label {
  font-size: var(--fs-base);
  padding: 0;
  font-weight: var(--fw-medium);
}

.sign-in-panel__form .text-input-label,
.sign-in-panel__form .password-label {
  color: var(--form-color-interaction-text-label-primary);
  font-weight: var(--fw-medium);
}

.sign-in-panel__form .text-input {
  margin-top: 0;
  margin-bottom: var(--spacing-m);
}

.sign-in-panel__form .outer-wrapper {
  border-radius: var(--form-border-interaction-border-radius-primary);
  border: 2px solid var(--form-color-interaction-border-primary);
}

.sign-in-panel__form .input-field {
  border-radius: var(--form-border-interaction-border-radius-primary);
}

.sign-in-panel__form .field-error label {
  margin-left: 0;
}

.panel .sign-in-panel__show-password {
  gap: var(--spacing-s);
  margin-bottom: var(--spacing-m);
  display: flex;
  align-items: center;
}

.panel .sign-in-panel__show-password label {
  font-weight: var(--fw-medium);
}

.panel .sign-in-panel__show-password input[type="checkbox"] {
  transform: unset;
}

.panel .sign-in-panel__show-password input[type="checkbox"] {
  border: 2px solid var(--color-universal-secondary-a);
  margin-bottom: var(--spacing-m);
}

.panel .sign-in-panel__submit {
  margin-bottom: 18px;
}

.panel .sign-in-panel__submit button {
  color: var(--button-color-action-text-primary);
  background-color: var(--button-color-action-background-primary);
  border-radius: var(--button-action-border-radius);
  padding: 16px var(--spacing-l) 16px var(--spacing-l);
  box-shadow: unset;
}

.panel .sign-in-panel__submit span {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.panel .sign-in-panel__submit button:hover,
.panel .sign-in-panel__submit button:focus,
.panel .sign-in-panel__submit button:hover:not(.disabled) {
  text-decoration: underline;
  background-color: var(--button-color-action-background-primary-hover);
}

.panel .sign-in-panel__submit span:focus {
  transition: none;
  outline: 2px solid var(--form-color-interaction-border-primary-focus);
  outline-offset: 3px;
}

.sign-in-panel__form .forgot-password-link {
  color: var(--link-color-action-text-primary);
  text-decoration: underline;
  padding: 0;
  border: none;
  cursor: pointer;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-medium);
}

.sign-in-panel__form .forgot-password-link:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.password-labels-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* this is a hidden label for input box, this simplifies testing. The visible label for this input is in the same
line with forgot password link to simplify styling
 */
.sign-in-panel__form .text-input-labels-container .text-input-label[for="2"] {
  display: none;
}
@media only screen and (min-width: 744px) {
  .sign-in-panel__form .password-label {
    width: 310px;
  }
}
