.icon-box-chevron {
  display: inline-flex;
  align-items: center;
}

.icon-box.icon-s {
  width: var(--icon-sizing-xs);
  height: var(--icon-sizing-xs);
}
.icon-box.icon-l {
  width: var(--icon-sizing-l);
  height: var(--icon-sizing-l);
}

.icon-box-chevron svg {
  stroke-width: 2;
  height: var(--icon-sizing-s);
  width: var(--icon-sizing-s);
  stroke: var(--color-universal-secondary-b);
}
