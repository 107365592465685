.button {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: var(--button-action-border-radius);
  outline: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
  text-decoration: none;
  white-space: nowrap;
  color: var(--color-universal-natural-black);
  padding: 9px 24px;
}

.button span {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-medium);
  text-align: start;
}
.button:hover,
.button:focus {
  text-decoration: none;
}

.button:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.button svg {
  width: var(--icon-sizing-s);
  height: var(--icon-sizing-s);
}

.button.primary {
  background-color: var(--button-color-action-background-primary);
  box-shadow: var(--button-action-shadow-primary);
  color: var(--button-color-action-text-primary);
  border: var(--button-action-border-primary);
}

.onex .button.primary {
  border-radius: var(--border-radius-s);
  padding: 5px 20px;
}
.button.primary:hover:not(.disabled) {
  background-color: var(--button-color-action-background-primary-hover);
  box-shadow: var(--button-action-shadow-primary-hover);
  color: var(--button-color-action-text-primary-hover);
  border: var(--button-action-border-primary-hover);
}

.onex .primary:hover:not(.disabled) {
  border-radius: var(--border-radius-s);
  padding: 5px 20px;
}
.button.primary svg {
  fill: var(--button-color-action-text-primary);
  stroke: var(--button-color-action-text-primary);
}
.button.primary svg:hover:not(.disabled) {
  fill: var(--button-color-action-text-primary-hover);
  stroke: var(--button-color-action-text-primary-hover);
}

.button.hide-on-mobile-only.regular.secondary {
  max-width: 50px;
}

.button.secondary {
  background-color: var(--button-color-action-background-secondary);
  box-shadow: var(--button-action-shadow-secondary);
  color: var(--button-color-action-text-secondary);
  border: var(--button-action-border-secondary);
}

.onex .button.secondary.small {
  padding: 5px 20px;
  border-radius: var(--border-radius-s);
}

.button.secondary:hover:not(.disabled) {
  background-color: var(--button-color-action-background-secondary-hover);
  box-shadow: var(--button-action-shadow-secondary-hover);
  color: var(--button-color-action-text-secondary-hover);
  border: var(--button-action-border-secondary-hover);
}

.button.button.secondary svg {
  fill: var(--button-color-action-text-secondary);
  stroke: var(--button-color-action-text-secondary);
}
.button.button.secondary:hover svg {
  fill: var(--button-color-action-text-secondary-hover);
  stroke: var(--button-color-action-text-secondary-hover);
}

.button.secondary:hover span {
  fill: var(--button-color-action-text-secondary-hover);
  stroke: var(--button-color-action-text-secondary-hover);
}

.button.tertiary {
  background-color: var(--button-color-action-background-tertiary);
  box-shadow: var(--button-action-shadow-tertiary);
  color: var(--button-color-action-text-tertiary);
  border: var(--button-action-border-tertiary);
}

.button.tertiary:hover:not(.disabled) {
  background-color: var(--button-color-action-background-tertiary-hover);
  box-shadow: var(--button-action-shadow-tertiary-hover);
  color: var(--button-color-action-text-tertiary-hover);
  border: var(--button-action-border-tertiary-hover);
}

.button.tertiary span {
  font-weight: var(--fw-medium);
}

.button.tertiary svg {
  fill: var(--button-color-action-text-tertiary);
  stroke: var(--button-color-action-text-tertiary);
}
.button.tertiary:hover:not(.disabled) svg {
  fill: var(--button-color-action-text-tertiary-hover);
  stroke: var(--button-color-action-text-tertiary-hover);
}

.button.menu {
  padding: 3px 10px;
  background-color: var(
    --component-color-interaction-background-secondary-hover
  );
  color: var(--component-color-interaction-background-secondary-hover);
}
.button.menu span {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  font-weight: var(--fw-regular);
  color: var(--button-color-action-background-primary-hover);
}
.button.menu.menu-open {
  background-color: var(
    --component-color-interaction-background-secondary-hover
  );
  color: var(--button-color-action-background-primary-hover);
}

.button.tiny {
  height: 34px;
  width: 34px;
  border-radius: var(--border-radius-l);
  padding: 5px;
  border: 2px solid #ececec;
  outline: 1px solid;
  outline-offset: 3px;
  outline-color: var(--color-universal-secondary-e);
}

.button.small {
  padding: 5px 10px;
  border-radius: var(--button-action-border-radius);
  font-size: var(--fs-xs);
}

.oneX .button.small {
  padding: 5px 20px;
}

.button.small span {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  font-weight: var(--fw-medium);
}

/* .button.tertiary.small:hover:not(.disabled) span {
  font-weight: var(--fw-bold);
} */

.button.large {
  padding: 15px 30px;
  font-size: var(--fs-l);
  line-height: var(--lh-l);
}

.button.xlarge {
  padding: 20px 35px;
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
}

.button.maxwidth {
  padding: 20px;
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  width: 100%;
}

.icon-in-button {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 0;
}

.icon-position-left {
  flex-direction: row-reverse;
}
.icon-position-left .icon-in-button {
  margin-right: 10px;
  margin-left: 0;
}

.button:disabled,
.button.danger-remove:disabled {
  pointer-events: none;
  cursor: default;
  background: var(--button-color-action-background-disabled);
  color: var(--button-color-action-text-disabled);
  border: none;
  opacity: 100%;
}
.button.danger-remove:disabled svg {
  stroke: var(--component-color-action-text-disabled);
  fill: var(--component-color-action-text-disabled);
}

.button.danger-primary {
  background-color: var(--button-color-action-background-danger);
  border: var(--button-action-border-danger);
  color: var(--button-color-action-text-danger);
}
.button.danger-primary:disabled {
  background-color: var(--button-color-action-background-disabled);
  border: none;
  color: var(--button-color-action-text-disabled);
}

.button.danger-primary:hover {
  background-color: var(--button-color-action-background-danger-hover);
  border: 1px solid var(--button-color-action-background-danger-hover);
  color: var(--button-color-action-text-danger-hover);
}

.ONE_X .modal .button.danger-primary {
  background-color: var(--button-color-action-background-primary);
  border: 1px solid var(--button-color-action-background-primary);
  color: var(--button-primary-primary-base-color);
}
.ONE_X .modal .button.danger-primary:hover {
  background-color: var(--button-color-action-background-primary-hover);
  border: 1px solid var(--button-color-action-background-primary-hover);
  color: var(--button-color-action-text-primary-hover);
}

.button.danger-primary svg {
  stroke: var(--button-color-action-text-danger);
  fill: var(--button-color-action-text-danger);
}
.button.danger-primary:hover svg {
  stroke: var(--button-color-action-text-danger-hover);
  fill: var(--button-color-action-text-danger-hover);
}

.button.danger-remove {
  background: var(--button-color-action-background-remove);
  border: var(--button-action-border-remove);
  color: var(--button-color-action-text-remove);
}

.button.danger-remove span {
  font-weight: 400;
}

.button.danger-remove:hover {
  background: var(--button-color-action-background-remove-hover);
  border: 1px solid var(--button-color-action-text-remove-hover);
  color: var(--button-color-action-text-remove-hover);
}

.button.danger-remove svg {
  stroke: var(--button-color-action-text-remove);
  fill: var(--button-color-action-text-remove);
}
.button.danger-remove:hover svg {
  stroke: var(--button-color-action-text-remove-hover);
  fill: var(--button-color-action-text-remove-hover);
}

@media (max-width: 767px) {
  .button.full-width-on-mobile {
    width: 100%;
  }
}

.button-children.onex {
  font-weight: var(--fw-medium) !important;
}

.button-spinner {
  margin-left: 5px;
}

.button.link span {
  color: var(--button-color-action-text-link);
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-regular);
  text-decoration: underline;
  cursor: pointer;
}
