.toast {
  padding: var(--spacing-m) 45px var(--spacing-m) 43px;
  border-left: 21px solid;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 473px; /* Figma specifies 473px */
  position: fixed;
  right: var(--spacing-l);
  bottom: var(--spacing-l);
  z-index: var(--z-index-1);
}

.toast--info {
  border-color: var(--alert-message-color-feedback-border-info);
}

.toast--success {
  border-color: var(--alert-message-color-feedback-border-success);
}

.toast-header {
  display: flex;
  justify-content: space-between;
}

.toast .toast-header h1 {
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
}

.toast .close-button,
.toast .toast-header h1 {
  margin-bottom: 23px;
}

.toast p {
  font-size: var(--fs-xl);
  margin-bottom: 0px;
}

.toast--success h1 {
  color: var(--alert-message-color-feedback-border-success);
}

.toast svg {
  margin-right: 12px;
}

.toast .close-button svg {
  margin: 0px;
}

.toast button {
  padding: 0px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-then-out {
  animation: fade-in ease 2.5s, fade-out 2.5s ease 6.5s forwards;
}

@media (max-width: 744px) {
  .toast {
    max-width: 90vw;
    position: fixed;
    left: 50%;
    transform: translateX(-56%);
    margin: var(--spacing-s);
  }
}
