.notification-pop-up-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  animation: slide-in 1s ease-in-out forwards;
  z-index: var(--z-index-4plus2);
}

.notification-pop-up-container .closeButton {
  padding: 0;
  margin-left: auto;
  width: var(--icon-sizing-l);
  height: var(--icon-sizing-l);
}

.notification-pop-up-container .closeButton svg {
  width: 100%;
  height: 100%;
  min-height: var(--icon-sizing-l);
  min-width: var(--icon-sizing-l);
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
}

.notification-pop-up {
  border: 1px solid var(--color-universal-secondary-d);
  box-sizing: border-box;
  box-shadow: var(--shadow-2);
  background-color: var(--color-universal-natural-white);
  border-radius: var(--border-radius-s);
  padding: 15px 15px 15px 15px;
  box-shadow: var(--shadow-1);
  width: 424px;
  pointer-events: all;
}

.notification-pop-up .view-button {
  margin-left: 60%;
}

.slide-in {
  animation: slide-in 1s ease-in-out forwards;
}

.slide-out {
  animation: slide-in 1s ease-in-out forwards,
    slide-out 1s 8s ease-in-out forwards;
}

.notification-pop-up .center-vertical {
  align-items: center;
}

.view-button-container {
  text-align: right;
}

/*Mobile*/
@media (max-width: 768px) {
  .notification-pop-up {
    left: 10px;
    right: 10px;
    transform: translate(0, 0);
    width: unset;
  }
  .notification-pop-up .notification-text {
    text-align: left !important;
  }
}

.notification-pop-up :hover {
  cursor: pointer;
}

.notification-pop-up .single-notification-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  align-items: center;
  overflow: hidden;

  text-overflow: ellipsis;
}
.notification-pop-up .single-notification-message:last-child {
  margin-bottom: 0;
}

.notification-pop-up .notification-text {
  display: flex;
  font-weight: var(--fw-regular);
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  color: var(--color-universal-secondary-b);
  margin: 0px 17px 0 18px;
}

.notification-pop-up .notification-text span {
  font-size: var(--fs-s);
}

.notification-pop-up .notification-text div {
  font-size: var(--fs-s);
}

.notification-pop-up .notification-text div b {
  font-size: var(--fs-s);
}

.notification-pop-up .notification-text li {
  list-style: disc;
}

.notification-pop-up .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.notification-pop-up .center {
  align-self: center;
}
.notification-pop-up .path {
  fill: var(--color-universal-natural-white);
}

.notification-pop-up .notification-popup-icon {
  width: var(--icon-sizing-l);
  height: var(--icon-sizing-l);
  display: inline-flex;
  align-items: flex-start;
}
.notification-pop-up .notification-popup-icon svg {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  min-width: var(--icon-sizing-l);
  height: auto;
}

.notification-pop-up .red-icon svg {
  fill: var(--component-color-feedback-danger);
  stroke: var(--component-color-feedback-danger);
}

.notification-pop-up .green-icon svg {
  stroke: white;
}

.scottish-widows .notification-pop-up .green-icon svg {
  fill: white;
  stroke: var(--color-functional-success-base);
}

.notification-pop-up .notification-bell svg {
  fill: none;
}

@keyframes slide-in {
  0% {
    top: -10%;
  }
  100% {
    top: 25%;
  }
}

@keyframes slide-out {
  0% {
    top: 25%;
  }
  99% {
    top: -10%;
  }
  100% {
    top: -100%;
  }
}

@media (max-width: 563px) {
  .notification-pop-up-container {
    width: 90%;
  }
}
