.radio-input {
  display: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-color-interaction-background-primary);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 30px;
  height: 30px;
  border: 1px solid var(--form-color-interaction-border-primary);
  border-radius: 50%;
  display: grid;
  place-content: center;
  box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.5);
  cursor: pointer;
}

.radio-input:focus {
  outline: 2px solid var(--form-color-interaction-border-primary-focus);
}

.radio-input:disabled {
  background-color: var(--component-color-action-background-disabled);
  pointer-events: none;
}

.radio-input::before {
  content: "";
  /* larger size and less scaling to prevent it from disappearing on zoom due to browser rounding */
  width: 180px;
  height: 180px;
  border-radius: 50%;
  transform: scale(0);
  box-shadow: inset 180px 180px var(--color-universal-secondary-b);
}

.radio-input:checked::before {
  transform: scale(0.1);
}
