.top-bar-moneyhub-mobile {
    background-color: #c0c0c0;
    position:sticky;
    top:0;
    width:100%;
    z-index:100;
}

.top-bar-moneyhub-tablet {

    background-color: #c0c0c0;
    position:sticky;
    top:0;
    width:100%;
    z-index:100;
}

.top-bar-moneyhub-desktop {

    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

}