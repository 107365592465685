.document-name-and-category-file-upload {
  display: none;
}

.document-name-and-category-processing-file {
  display: flex;
  align-items: center;
}

.document-name-and-category-add-page {
  margin-top: 28px;
  margin-bottom: 40px;
  text-decoration: underline;
  color: #00729a;
  cursor: pointer;
}
.document-name-and-category-add-page:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}
.document-name-and-category-file-text {
  font-size: var(--fs-base);
  font-weight: var(--fw-regular);
  margin-bottom: 40px;
  margin-top: 40px;
}

.document-name-and-category-text {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: var(--fs-base);
  display: flex;

  .icon {
    margin-right: 10px;
  }

  .icon svg {
    width: 26px;
    height: 26px;
  }

  .inner {
    font-weight: var(--fw-bold);
  }
}

.document-name-and-category-error {
  margin-top: 40px;
}
