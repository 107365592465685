.unauthorised-page {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 40px;
  height: 100%;
  width: 100%;
  background-color: #f3f9fd;

  h1 {
    font-size: var(--fs-display-l);
    line-height: var(--lh-display-l);
    font-weight: var(--fw-bold);
    margin: 0;
  }

  h5 {
    font-size: 24px;
  }
  p {
    margin: 0;
  }
  .phone-lines-info {
    margin-top: 15px;
  }
}
