.cover-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

@media (max-width: 767px) {
  .cover-screen {
    width: var(--max-page-width);
    height: 100%;
  }
}

.backdrop {
  z-index: var(--z-index-4);
  background-color: var(--modal-background);
}

.session-timeout-backdrop {
  background-color: #484848;
}

.invisible-backdrop {
  z-index: var(--z-index-4);
  cursor: auto;
  transform: scale(1);
}

.modal-wrapper {
  z-index: var(--z-index-4plus1);
  display: flex;
  align-items: center;
  pointer-events: none;
}

.modal-wrapper.ONE_LEGACY {
  height: 80vh;
}

.ONE_X.modal-wrapper {
  align-items: unset;
  top: 5%;
}

.modal {
  position: relative;
  display: flex;
  margin: 50px auto 40px auto;
  border-radius: var(--border-radius-s);
  background-color: var(--modal-color-context-background-primary);
  padding: 30px;
  height: fit-content;
  max-height: 90vh;
  max-height: -webkit-fill-available;
  width: 800px;
  max-width: -webkit-fill-available;
  pointer-events: all;
  font-family: var(--body-font-text-base);
}

.modal--with-max-width {
  max-width: 580px;
}

.modal-content-wrapper .modal-content .outer-wrapper,
.modal-content-wrapper .modal-content .text-area .outer-wrapper {
  margin-right: var(--spacing-xxx-s);
}
@media (max-width: 767px) {
  .modal {
    margin: 0px;
    padding: 20px;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: unset;
  }
}

.modal-content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 2px;
  width: 100%;

  .modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    /* This padding was added for COOP rings */
    padding: 0 5px;
    scrollbar-color: var(--modal-color-interaction-scrollbar-foreground)
      var(--modal-color-interaction-scrollbar-background);
    scrollbar-width: thin;
  }
}

.modal-content.with-scrollbar {
  padding-right: 35px;
  padding-left: 5px;
}

@media (max-width: 767px) {
  .modal-content-wrapper {
    margin-bottom: 40px;
  }

  .modal-content.with-scrollbar {
    padding-right: 12px;
  }

  .modal-content {
    flex-grow: 1;
  }
}

.modal-content-wrapper::-webkit-scrollbar {
  width: 8px;
  background: var(--color-universal-secondary-e);
  border-radius: var(--border-radius-m);
}
.modal-content-wrapper::-webkit-scrollbar-thumb {
  background: var(--color-universal-secondary-a);
  border-radius: var(--border-radius-m);
  border: 3px solid transparent;
  background-clip: content-box;
}

.modal .close-button {
  position: absolute;
  right: 40px;
  color: var(--color-universal-natural-white);
  z-index: var(--z-index-3);
}

.modal .close-button:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.modal .close-button .icon-box {
  width: var(--icon-sizing-m);
}

.modal .close-button .icon-box svg {
  width: 100%;
  height: auto;
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
}

@media (max-width: 767px) {
  .modal .close-button {
    right: 20px;
    top: 35px;
    fill: var(--color-universal-natural-black);
  }
}

.modal-content-wrapper > hr {
  margin: 0px 0px 20px 0px;
  color: var(--color-universal-secondary-d);
  opacity: 1;
}

.modal .modal-content {
  background-color: var(--modal-color-context-background-primary);
  color: var(--color-universal-secondary-b);
  height: auto;
  line-height: var(--lh-base);
  font-size: var(--fs-s);
}

@media (max-width: 767px) {
  .modal .modal-content {
    padding-bottom: var(--spacing-xxxx-l);
  }
}

.modal .header {
  color: var(--color-universal-secondary-b);
  font-weight: var(--fw-regular);
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  padding-bottom: 30px;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 0;
}

.header__border-bottom--none {
  border-bottom: none;
}

.header__border-bottom--base {
  border-bottom: 1px solid var(--divider-color-context-background-primary);
}

.quilter .modal .header {
  border-bottom: none;
}

.modal .header.has-subheader {
  padding-bottom: 2px;
}

.create-client-modal.header,
.ONE_X .modal .header {
  border-bottom: none;
}

@media (max-width: 1229px) {
  .modal .header {
    font-size: var(--fs-xl);
    line-height: var(--lh-xl);
  }
}

@media (max-width: 767px) {
  .modal .header {
    font-size: var(--fs-l);
    line-height: var(--lh-l);
  }
}

.modal .sub-header {
  font-weight: var(--fw-light);
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  padding-bottom: 30px;
  padding-right: 5px;
  padding-left: 5px;
  color: var(--color-universal-secondary-b);
  display: flex;
  align-items: center;
  gap: var(--spacing-x-s);
  margin: 0;
}

.modal .sub-header .icon-box {
  width: var(--icon-sizing-m);
}

.modal .sub-header .icon-box svg {
  width: 100%;
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
}

.modal .infoButton svg {
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
}

@media (max-width: 767px) {
  .modal .sub-header {
    font-size: var(--fs-base);
    line-height: var(--lh-base);
    width: calc(100% - var(--icon-sizing-m));
  }
}

.modal .modal-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    0deg,
    var(--modal-color-context-background-primary) 16.67%,
    rgba(255, 255, 255, 0) 127.19%
  );
  padding: 30px;
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
  display: flex;
  justify-content: flex-end;
}

.filter-sort-document-library.modal .modal-footer,
.ONE_X .modal .modal-footer {
  justify-content: space-between;
}

@media (max-width: 767px) {
  .modal .modal-content-wrapper .modal-footer {
    padding-top: var(--spacing-xx-s);
  }

  .modal .modal-footer {
    bottom: 0;
    left: 0;
    padding: 20px;
    padding-top: 36px;
    bottom: 60px;
  }

  .modal .modal-footer button:first-child:not(:only-child) {
    width: 50%;
    margin-right: 30px;
  }

  .modal .modal-footer button:last-child {
    width: 50%;
    float: right;
  }

  .landing-page-modal .modal-footer {
    position: relative;
    padding: 0px;
  }
  .unsaved-progress.modal-footer button:last-child {
    width: 45%;
  }
  .unsaved-progress.modal-footer button:first-child:not(:only-child) {
    width: 45%;
  }
}

.modal .left-button span {
  font-weight: var(--fw-regular) !important;
}

@media (max-width: 767px) {
  .landing-page-modal-backdrop {
    background-color: var(--modal-color-context-background-primary);
  }
}

.modal .modal-footer.THE_FAMILY_ELEPHANT {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 100%;
  background: linear-gradient(
    0deg,
    var(--modal-color-context-background-primary) 16.67%,
    rgba(255, 255, 255, 0) 127.19%
  );
  padding: 40px 60px;
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
}

.modal.add-tag .header {
  border-bottom: 0px solid transparent;
}

/* PDF Preview Modal specific styles */
.pdf-preview-modal.modal {
  padding: 20px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.pdf-preview-modal .modal-content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; /* Important for flex child to enable scrolling */
}

.pdf-preview-modal .modal-content {
  flex: 1;
  min-height: 0; /* Important for flex child to enable scrolling */
  padding: 0;
  overflow: hidden; /* Let the PDF container handle scrolling */
}

@media (max-width: 767px) {
  .pdf-preview-modal.modal {
    padding: 10px;
  }

  .pdf-preview-modal .modal-content-wrapper {
    margin-bottom: 0;
  }
}
