.text-area {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  border: 0;
  margin-bottom: 15px;
  vertical-align: top;
  width: 100%;
}

.text-area .label {
  display: block;
  margin: 20px 0 11px 0;
  height: fit-content;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
  color: var(--color-universal-secondary-b);
  border-radius: 3px 3px 0 0;
  font-weight: var(--fw-medium);
  line-height: 22px;
  letter-spacing: -0.01em;
}

.text-area .label.required:before {
  content: "* ";
  color: var(--component-color-feedback-danger);
}

.text-area .error-label {
  color: var(--component-color-feedback-danger);
  margin-bottom: 10px;
}

.text-area .input-wrapper {
  position: relative;
  background: var(--form-color-interaction-background-primary);
  border-radius: var(--border-radius-s);
  border: 1px solid var(--form-color-interaction-border-primary);
  box-shadow: none;
  min-height: 100px;
  transition: border-color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.text-area .input-wrapper.error {
  border-color: var(--component-color-feedback-danger);
}

.text-area .input-wrapper:focus-visible,
.input-box {
  border: 0;
  outline: 0;
}

.input-box:focus,
.input-box:focus-within {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
  border-radius: var(--border-radius-s);
}

.text-area .input-box {
  display: block;
  box-sizing: border-box;
  height: 100px;
  width: 100%;
  margin: 0;
  border: 0;
  background: none;
  resize: vertical;
  overflow: auto;
  min-height: 80px;
  -webkit-tap-highlight-color: transparent;
  padding: 10px 20px;
}

.text-area .input:focus {
  outline: 0;
}

#personal-note {
  font-size: var(--fs-xs);
}
