@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../OpenSans-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../OpenSans-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../OpenSans-SemiBold.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../OpenSans-Bold.ttf) format("truetype");
  font-weight: 700;
}
