.thumbnail-wrapper {
  position: relative;
}

.thumbnail-container {
  border: 1px solid var(--divider-color-context-background-primary);
  background-color: var(--color-universal-natural-grey);
  border-radius: var(--border-radius-s);
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
}

.thumbnail-container.small {
  padding: 5px;
  width: 62px;
  height: 44px;
  min-width: 62px;
}

.thumbnail-container.medium-1 {
  padding: 8px;
  width: 70px;
  height: 55px;
  min-width: 70px;
}

.thumbnail-container.medium-2 {
  padding: 8px;
  width: 70px;
  height: 60px;
  min-width: 70px;
}

.thumbnail-container.large-1 {
  padding: 10px;
  width: 99px;
  height: 88px;
  min-width: 99px;
}

.thumbnail-container.large-2 {
  padding: 8px;
  width: 100px;
  height: 70px;
  min-width: 100px;
}

.thumbnail-container.large-3 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 50%;
}

.thumbnail-container.large-4 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  height: 115px;
}
@media (max-width: 767px) {
  .thumbnail-container.large-4 {
    width: 125px;
    height: 85px;
  }
}

.thumbnail-container.thumbnail-fallback {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.thumbnail-container .thumbnail-icon.icon-box {
  width: 42px !important;
  height: auto !important;
}

.thumbnail-container.small .thumbnail-icon.icon-box {
  width: 32px !important;
}

.thumbnail-container .file-extension {
  font-weight: var(--fw-bold);
  margin-top: -20px;
}

.thumbnail-container.small .file-extension {
  font-size: var(--fs-s);
  margin-top: -23px;
}

.thumbnail-container img {
  max-width: 100%;
  width: 100%;
  box-shadow: var(--shadow-2);
  border-radius: var(--border-radius-s);
  box-shadow: var(--shadow-2);
}
