.reset-password-page {
  display: flex;
  height: 100%;
}

.reset-password-page .left-navigation {
  /* This a workaround because the normal left-navigation which is used for all other themes has width 250px rather than 305px */
  max-width: unset;
  color: var(--lozenge-color-feedback-text-info);
  height: 100%;
}

.reset-password-page .reset-password-page__right-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.reset-password-page__bottom-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 50px 40px 50px;
}

.reset-password-page_confirmation {
  display: flex;
  flex-direction: column;
}

.reset-password-page_confirmation h1 {
  font-weight: var(--fw-bold);
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  margin-bottom: var(--spacing-l);
}

.reset-password-page-confirmation__success-message {
  font-weight: var(--fw-regular);
  font-size: var(--fs-m);
  line-height: var(--fs-xxxl);
  margin-bottom: var(--spacing-xx-s);
}
.reset-password-page-confirmation__continue {
  font-weight: var(--fw-regular);
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  margin-bottom: var(--spacing-l);
}

.reset-password-page_confirmation button:disabled {
  color: var(--button-color-action-text-primary);
  background-color: var(--button-color-action-background-primary);
}

@media (max-width: 786px) {
  .account-document-library .search-input {
    width: 100%;
  }

  .reset-password-page_confirmation {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .reset-password-page__bottom-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reset-password-page .reset-password-page__right-content {
    justify-content: unset;
  }
}
