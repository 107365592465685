.panel .sign-in-panel__logo {
  margin-bottom: var(--spacing-l);
}

.panel .sign-in-panel__title {
  font-weight: 600;
  font-size: 46px;
  line-height: 59px;
  margin-bottom: var(--spacing-l);
}

.panel .sign-in-panel__paragraph {
  margin-bottom: var(--spacing-m);
  font-size: var(--fs-m);
  line-height: var(--lh-m);
}
