.forgot-password-panel {
  background-color: var(--panel-color-background-primary);
  display: flex;
  padding: 30px 48px 48px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  max-width: 596px;
}

@media only screen and (min-width: 1920px) {
  .forgot-password-panel {
    max-width: 800px;
  }
}

@media only screen and (max-width: 375px) {
  .forgot-password-panel {
    padding: 30px 20px 30px 20px;
  }
}

.forgot-password-panel .forgot-password-panel__header {
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 59px;
}

.forgot-password-text {
  font-size: 46px;
  line-height: 59px;
  margin-right: 10px;
  font-weight: 600;
}

.forgot-password-panel__content,
.forgot-password-input-container {
  display: flex;
  flex-direction: column;
}

.forgot-password-input-container .text-input .outer-wrapper {
  height: 50px;
  border-width: 2px;
}

.forgot-password-input-container .text-input .text-input-label {
  padding: 0;
  font-size: var(--fs-base);
}

.forgot-password-input-container .field-error {
  margin-top: 0;
}

.forgot-password-input-container .field-error label {
  margin-left: 0;
  font-size: var(--fs-base);
  font-weight: var(--fw-regular);
  line-height: var(--lh-base);
}

.forgot-password-panel__content .sub-text {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 10px;
}

.forgot-password-btn-container button {
  color: var(--button-color-action-text-primary);
  width: 216px;
  background-color: var(--button-color-action-background-primary);
}

.forgot-password-btn {
  border-radius: 8px;
  margin-right: var(--lh-xs);
  font-size: 40px;
}

.forgot-password-panel__footer {
  width: 100%;
}

.forgot-password-panel__footer hr {
  background: var(--divider-color-context-background-primary);
  margin: 0;
}

.forgot-password-panel__footer button {
  color: var(--link-color-action-text-primary);
  text-decoration: underline;
  padding: 0;
  margin-top: 40px;
  border: none;
}

.forgot-password-panel__content .forgot-password-btn-container button {
  color: var(--button-color-action-text-primary);
  width: 216px;
  background-color: var(--button-color-action-background-primary);
}
