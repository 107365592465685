.notification-icon {
  display: flex;
  align-items: center;
  margin-left: 30px;
  padding: 5px 8px 4px 8px;
}

.notification-icon .notification-bell {
  margin-right: 8px;
}

.notifications-icon-text,
.notifications-new {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.notification-icon svg {
  width: 16px;
  stroke: var(--color-universal-secondary-b);
}

.notifications-new {
  margin-left: 5px;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  border-radius: 21px;
  background-color: var(--color-universal-primary-base);
  font-weight: var(--fw-medium);
  color: white;
}
.coop .notifications-new {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  font-weight: var(--fw-medium);
  line-height: var(--lh-l);
}
.new-notification {
  background-color: var(--notification-color-background-new);
  border-radius: 100px;
  width: fit-content;
  align-items: baseline;
}

@media (max-width: 1229px) {
  .notification-icon {
    display: none;
  }
}
