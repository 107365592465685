.legado * {
  font-family: var(--body-font-text-base);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  letter-spacing: var(--letter-spacing-base);
  font-weight: var(--fw-regular);
}

.legado h1 {
  font-size: var(--fs-l);
  line-height: var(--lh-l);
  font-family: var(--font-family-heading);
}
.legado h2 {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  font-family: var(--font-family-heading);
  font-weight: var(--fw-bold);
}
.legado h3 {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  font-family: var(--font-family-heading);
}
.legado h4 {
  font-family: var(--font-family-heading);
  font-weight: var(--fw-bold);
}
.legado h5 {
  font-family: var(--font-family-heading);
}

/* connectivity logos need stroke width to be 0 and no fill */
.legado .connectivity-svg {
  stroke-width: 0px;
  fill: none;
}

.page-header {
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  font-weight: var(--fw-bold);
  margin: 0;
}

.hover-blue {
  transition: 0.2s;
  border: 2px solid transparent;
}
.hover-blue:hover {
  background: var(--color-universal-secondary-f);
  border-color: var(--color-universal-secondary-h);
}

.bg-secondary-e {
  background-color: var(--color-universal-secondary-e);
}
.bg-primary-grey {
  background-color: var(--color-universal-primary-grey);
}

.border-bottom-secondary-d {
  border-bottom: 1px solid var(--color-universal-secondary-d);
}

.grey-line {
  border-bottom: 1px solid var(--color-universal-secondary-d);
  height: 1px;
}

.fs-s {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.fs-m {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
}

.link-box {
  display: inline-block;
  padding: 5px 15px;
  margin-left: -15px;
  margin-right: -15px;
  cursor: pointer;
  border-radius: 100px;
  transition: 0.3s;
}
.link-box:hover {
  background: var(--component-color-interaction-background-secondary-hover);
}
.link {
  font-weight: var(--fw-regular);
  font-size: var(--fs-s);
  color: var(--link-color-action-text-primary);
  transition: border-color 0.3s;
  cursor: pointer;
}

.link:focus,
.rn-link:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.link-box .link:hover {
  border-color: transparent;
}

.color-secondary-b {
  color: var(--color-universal-secondary-b);
}

.text-xs {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
}
.text-s {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-light);
}
.text-m {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
}
.text-l {
  font-size: var(--fs-l);
  line-height: var(--lh-l);
}

/* ICON DEFAULT STYLES */

svg.fill,
path.fill,
rect.fill {
  fill: var(--color-universal-secondary-b);
}
path.reverse,
rect.reverse {
  fill: var(--page-color-context-background-primary);
  stroke: var(--page-color-context-background-primary);
}

svg .custom-1 {
  fill: var(--color-universal-secondary-g);
}
svg .second-color-fill {
  fill: var(--color-universal-secondary-g);
}
svg .second-color-stroke {
  stroke: var(--color-universal-secondary-g);
}

svg .fill-white {
  fill: var(--color-universal-natural-white);
}
svg .fill-black {
  fill: var(--color-universal-secondary-b);
}
svg .stroke-white {
  stroke: var(--color-universal-natural-white);
}
svg .stroke-black {
  stroke: var(--color-universal-secondary-b);
}

.welcome-color-1 svg,
.one-x .welcome-color-1 svg {
  fill: var(--color-universal-secondary-c);
  stroke: var(--color-universal-secondary-c);
}

.welcome-color-1 svg .custom-1 {
  fill: var(--page-color-context-background-primary);
}
.one-x .welcome-color-2 svg .custom-1 {
  fill: none;
}

.welcome-color-2 svg {
  fill: var(--color-universal-secondary-h);
  stroke: var(--color-universal-secondary-h);
}

.wps-advisory .welcome-color-1 svg,
.wps-advisory .welcome-color-2 svg {
  stroke-width: 0.2px;
}


.scottish-widows .welcome-color-1 svg,
.scottish-widows .welcome-color-2 svg {
  stroke: none;
}

.welcome-color-1 svg.reverse {
  stroke: var(--color-universal-secondary-c);
  fill: none;
}
.welcome-color-2 svg.reverse,
.welcome-color-2 svg .reverse {
  stroke: var(--color-universal-secondary-h);
  fill: none;
}

.welcome-color-2 svg .custom-1,
.welcome-color-2 svg.reverse .custom-1,
.welcome-color-2 svg .reverse.custom-1 {
  fill: var(--color-universal-secondary-g);
}
.welcome-color-1 svg.reverse .reverse {
  fill: var(--color-universal-secondary-c);
  stroke: none;
}
.welcome-color-2 svg.reverse .reverse {
  fill: var(--color-universal-secondary-h);
  stroke: none;
}

.functional-danger-base svg {
  fill: var(--component-color-feedback-danger);
  stroke: var(--component-color-feedback-danger);
}
.functional-success-base svg {
  fill: var(--color-functional-success-base);
  stroke: white;
}

.icon-box .icon-sizing-xs svg {
  width: var(--icon-sizing-xs);
  height: var(--icon-sizing-xs);
}

.icon-box .icon-sizing-s svg {
  width: var(--icon-sizing-s);
  height: var(--icon-sizing-s);
}

.icon-box .icon-sizing-m svg {
  width: var(--icon-sizing-m);
  height: var(--icon-sizing-m);
}

.icon-box .icon-sizing-l svg {
  width: var(--icon-sizing-l);
  height: var(--icon-sizing-l);
}

.icon-box .icon-sizing-xl svg {
  width: var(--icon-sizing-xl);
  height: var(--icon-sizing-xl);
}

/* ICON DEFAULT SIZING */

.icon-sizing-xl {
  width: var(--icon-sizing-xl);
}

.icon-sizing-l {
  width: var(--icon-sizing-l);
}

.icon-sizing-m {
  width: var(--icon-sizing-m);
}

.icon-sizing-s {
  width: var(--icon-sizing-s);
}

.icon-sizing-xs {
  width: var(--icon-sizing-xs);
}

/* BORDER RADIUS */

.border-radius-s {
  border-radius: var(--border-radius-s);
}

.border-radius-m {
  border-radius: var(--border-radius-m);
}

.border-radius-l {
  border-radius: var(--border-radius-l);
}

/* Ignore added paths (className=custom-1) in svgs in oneX theme */
.one-x .custom-1 {
  fill: none !important;
}

/* Aviva logos styling */
.hide-on-mobile-only path.grey,
.icon path.grey,
.icon-box path.grey,
.welcome-color-2 path.grey {
  stroke: #f9f9f9;
  fill: #f9f9f9;
}

.hide-on-mobile-only path.mid-grey,
.icon path.mid-grey,
.icon-box path.mid-grey,
.welcome-color-2 path.mid-grey {
  stroke: #d7d7d7;
  fill: #d7d7d7;
}

.hide-on-mobile-only path.blue,
.icon path.blue,
.icon-box path.blue,
.welcome-color-2 path.blue {
  stroke: #0296c8;
  fill: #0296c8;
}

.hide-on-mobile-only path.dark-blue,
.icon path.dark-blue,
.icon-box path.dark-blue,
.welcome-color-2 path.dark-blue {
  stroke: #106cb2;
  fill: #106cb2;
}

.hide-on-mobile-only path.yellow,
.icon path.yellow,
.icon-box path.yellow,
.welcome-color-2 path.yellow {
  stroke: #ffd900;
  fill: #ffd900;
}

.welcome-color-1 path.grey {
  stroke: #f9f9f9;
  fill: #f9f9f9;
}

.welcome-color-1 path.mid-grey {
  stroke: #d7d7d7;
  fill: #d7d7d7;
}

.welcome-color-1 path.light-grey {
  stroke: #eeeeee;
  fill: #eeeeee;
}

.welcome-color-1 path.dark-grey {
  stroke: #bebebe;
  fill: #bebebe;
}
/* Aviva logo end */
