@font-face {
  font-family: "Sharp Sans";
  src: local("Sharp Sans"),
    url(../SharpSansDispNo1/SharpSansDispNo1-Book.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Sharp Sans";
  src: local("Sharp Sans"),
    url(../SharpSansDispNo1/SharpSansDispNo1-Medium.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Sharp Sans";
  src: local("Sharp Sans"),
    url(../SharpSansDispNo1/SharpSansDispNo1-SemiBold.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Sharp Sans";
  src: local("Sharp Sans"),
    url(../SharpSansDispNo1/SharpSansDispNo1-Bold.ttf) format("truetype");
  font-weight: 700;
}
