.top-bar {
  height: 116px;
  min-height: 116px;
  background: var(--navbar-color-interaction-background-primary);
  position: relative;
  padding-top: 60px;
}

.top-bar svg {
  display: block;
  margin: auto auto 0px auto;
}
