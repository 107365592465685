.login-step__container {
  background-color: var(--page-color-context-background-secondary);
  display: flex;
  height: 100%;
}

.login-page .left-navigation {
  /* This a workaround because the normal left-navigation which is used for all other themes has width 250px rather than 305px */
  max-width: unset;
  color: var(--lozenge-color-feedback-text-info);
  height: 100%;
}

.login-page .login-page__right-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: unset;
}

.login-page__right-content.background--confirm-identity {
  background-color: unset;
}

.login-page__right-content-sign-in-panel {
  max-width: 596px;
}

.login-page__right-content-sign-in-panel,
.login-page__right-content-welcome-text {
  margin: auto;
}

.login-page__right-content-sign-in-panel,
.login-page__right-content-welcome-text h1 {
  font-weight: var(--fw-bold);
}

.login-page__right-content-welcome-text h1 {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1920px) {
  .login-page__right-content-sign-in-panel {
    max-width: 800px;
  }
}

.login-page__right-content-welcome-text {
  max-width: 596px;
}

@media only screen and (max-width: 1229px) {
  .login-step__container {
    display: flex;
    flex-direction: column;
    padding: 80px 74px 50px 74px;
  }

  .login-page__right-content-sign-in-panel {
    padding-bottom: 0px;
    margin-bottom: 40px;
    width: 596px;
  }

  .login-page__right-content-welcome-text {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 744px) {
  .login-page__right-content-sign-in-panel {
    margin: unset;
    margin-bottom: 40px;
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .login-step__container {
    padding: 0px;
  }
  .login-page__right-content-welcome-text {
    padding: 0px 20px 40px 20px;
  }
  .login-step__container .sign-in-panel {
    border-radius: 0px;
  }
}

@media only screen and (max-width: 375px) {
  .login-step__container {
    padding: 0px;
  }
  .login-page .login-page__right-content {
    justify-content: flex-start;
    padding-top: 0px;
  }
  .login-page__right-content-welcome-text {
    max-width: 355px;
  }
}
