.upload-file-to-case .upload-file-to-case-document-category {
  font-weight: var(--fw-bold);
  margin-right: 10px;
}

.upload-file-to-case .upload-file-to-case-description {
  margin-top: 40px;
  margin-bottom: 20px;
}

.upload-file-to-case .upload-file-to-case-file-input {
  display: none;
}

.upload-file-to-case .upload-file-to-case-single-file-upload-button,
.upload-file-to-case .upload-file-to-case-multifile-upload-button {
  margin-bottom: 20px;
}

.upload-file-to-case .upload-file-to-case-alert-message {
  margin-top: 40px;
}

.upload-file-to-case
  .upload-file-to-case-single-file-upload-button--submitting {
  display: flex;
  align-items: center;
}
.upload-file-to-case-tooltip-wrapper {
  display: flex;
  align-items: center;
  font-weight: var(--fw-medium);
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  gap: var(--spacing-xxx-s);
  margin-bottom: var(--spacing-xxx-s);
}

.upload-file-to-case .tooltip-message {
  width: 320px;
}
.upload-file-to-case-tooltip-wrapper .infoButton svg {
  fill: unset;
  stroke: unset;
  height: 24px;
  width: 26px;
}

.upload-file-to-case
  .upload-file-to-case-tooltip-wrapper.multifile
  .tooltip-message {
  transform: translateY(calc(-149%));
  left: 2px;
}

.upload-file-to-case
  .upload-file-to-case-tooltip-wrapper.single-file
  .tooltip-message {
  transform: translateY(calc(-123%));
  left: 2px;
}

.upload-file-to-case
  .upload-file-to-case-tooltip-wrapper
  .tooltip.open:not(.tooltip-hidden).direction-down:after {
  left: 0%;
  transform: translateX(10%) rotate(180deg);
  bottom: 20px;
  border-bottom: solid 12px var(--color-universal-secondary-a);
}

.upload-file-to-case .tooltip.open.direction-down:focus-visible {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}
