/* TopBar Header */

/* Warning: Background color for abrdn primary user, for topBar-abrdn-border is set in Main.scss */
.topBar-abrdn-border {
  background: var(--topBar-color-interaction-background-primary);
  border-bottom: var(--navbar-color-interaction-border) 1px solid;
  box-shadow: var(--navbar-box-shadow-primary);
}

.topBar-abrdn {
  padding-top: 30px;
  padding-bottom: 20px;
}

.topBar-abrdn_logo {
  width: 100%;
  max-width: 190px;
  height: 100%;
  max-height: 48px;
}

@media (min-width: 768px) {
  .topBar-abrdn {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .topBar-abrdn-headings {
    margin-left: 30px;
    display: flex;
    align-items: baseline;
  }
}

h1.topBar-abrdn-title {
  font-weight: var(--fw-medium);
  font-size: var(--fs-base);
  line-height: var(--lh-s);
  color: var(--color-universal-secondary-b);
  margin: 15px 0 0 0;
}

@media (min-width: 768px) {
  h1.topBar-abrdn-title {
    margin: 0 10px 0 0;
  }
}

.topBar-abrdn-subtitle {
  font-weight: var(--fw-light);
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  color: var(--color-universal-secondary-b);
  margin: 5px 0 0 0;
}

@media (min-width: 768px) {
  .topBar-abrdn-subtitle {
    margin: 0;
  }
}

/* Menu bar */
.abrdn-vanilla .legado-menubar {
  border-top: 0;
  border-bottom: 0;
  box-shadow: none;
}
.topBar-abrdn-border {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .abrdn-vanilla .legado-menubar {
    display: none;
  }
}

.abrdn-vanilla .legado-menubar .container {
  gap: 0px;
}

.abrdn-vanilla .search-bar .search-input {
  padding: 10px 10px 10px 40px;
  border-radius: var(--form-radius);
  height: auto;
}

.abrdn-vanilla .search-bar .search-icon {
  left: 15px;
  top: 9px;
}

.abrdn-vanilla .mainMenuDesktop-bar {
  display: block;
}

/* Search Bar */
.abrdn-vanilla .search-input {
  height: 44px;
}

/* Footer */
.abrdn-vanilla .footer {
  text-align: center;
  padding: 25px 0;
}

.abrdn-vanilla .footer-text {
  margin: 0;
  color: var(--color-universal-secondary-b);
  font-size: var(--fs-s);
  font-weight: var(--fw-light);
  line-height: var(--lh-base);
  letter-spacing: 0em;
}

/* Button */
.abrdn-vanilla .regular {
  padding: 10px 18px;
}

/* Pagination icons */
.abrdn-vanilla .pagination__button svg.icon-chevron-left-double path {
  stroke: var(--pagination-color-interaction-chevron);
}

.abrdn-vanilla .pagination__button .icon-chevron-left path {
  stroke: var(--pagination-color-interaction-chevron);
}

.abrdn-vanilla .pagination__button svg.icon-ellipsis path {
  fill: var(--pagination-color-interaction-chevron);
}

.abrdn-vanilla .pagination__button svg.icon-chevron-right-double path {
  stroke: var(--pagination-color-interaction-chevron);
}

.abrdn-vanilla .pagination__button .icon-chevron-right path {
  stroke: var(--pagination-color-interaction-chevron);
}

/* Modals */
.abrdn-vanilla .modal .header {
  border-bottom: none;
}

.abrdn-vanilla .pseudo-link-text {
  border-bottom: 1px dotted var(--pagination-color-interaction-chevron);
}
