.account-button {
  margin-left: 18px;
  border-radius: var(--profile-button-radius);
}

.account-button:focus-within {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.account-button .button.tiny {
  border-radius: var(--profile-button-radius);
  color: var(--profileButton-color-text);
  background-color: var(--profileButton-color-action-background);
  outline: var(--profileButton-action-outline);
  outline-offset: var(--profileButton-action-outline-offset);
}
