.one-x .top-bar {
  width: 100%;
  background-color: var(--color-universal-natural-black);
  color: var(--color-universal-natural-white);
}

.james-hay .top-bar {
  background-color: #360031;
}

.top-bar-top,
.top-bar-bottom,
.top-bar-top .inner,
.top-bar-bottom .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  padding-left: 32px;
}
.top-bar .top-bar-top {
  height: 60px;
}

.top-bar-top {
  padding-left: 0;
}

.display-theme-icons .logo svg {
  fill: var(--color-universal-natural-black);
  stroke: var(--color-universal-secondary-c);
}
.display-theme-icons .logo .custom-1 {
  fill: var(--color-universal-primary-base);
}
.display-theme-icons .logo-grey-bg svg {
  fill: var(--color-universal-natural-white);
  stroke: var(--color-universal-secondary-c);
}
.display-theme-icons .logo-grey-bg svg .custom-1 {
  fill: var(--color-seconary-c);
}

.top-bar .menu {
  display: flex;
  align-items: center;
}
.top-bar-top .menu > div {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid var(--color-universal-natural-grey);
}
.top-bar-top .menu > div:last-child {
  padding-right: 0;
  border-right: none;
}
.top-bar-top .menu > div span {
  font-size: var(--fs-xs);
}
.top-bar-top .menu > div svg {
  fill: var(--color-universal-natural-white);
  stroke: var(--color-universal-natural-white);
  display: inline-block;
  margin-right: 5px;
}

.top-bar-bottom {
  background-image: var(--bg-image-url-top-bar);
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 150px;
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);
}
.top-bar-bottom .large {
  font-size: var(--fs-xxl);
  line-height: var(--lh-xxl);
}
.top-bar-bottom .inner > div:last-child {
  text-align: right;
}

.sw-top-bar {
  height: 35px;
  background-color: var(--navbar-color-interaction-background-primary);
}

.theme-radios {
  display: flex;
  background: var(--color-universal-primary-grey);
  border-radius: var(--border-radius-l);
  margin-right: var(--spacing-s);
}

.theme-radios label {
  font-size: var(--fs-s);
  padding: var(--spacing-xxx-s) var(--spacing-x-s);
  border-radius: var(--border-radius-l);
  cursor: pointer;
}

.theme-radios input[type="radio"]:checked + label {
  background-color: var(--button-color-action-background-primary);
  box-shadow: var(--button-action-shadow-primary);
  color: var(--button-color-action-text-primary);
}

.legado-hub-header {
  display: flex;
  align-items: center;
}

.legado-hub-text {
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  margin: 18px 14px;
}

.legado-hub-text-demo {
  font-size: var(--fs-xs);
}
