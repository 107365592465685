.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-overflow {
  overflow: hidden;
  position: relative;
  height: calc((2 * var(--lh-m)));
}
.truncate-overflow::before {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
}
.truncate-overflow::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1rem;
  height: 1rem;
  background: var(--color-universal-natural-white);
}

@keyframes come-in {
  0% {
    opacity: 0;
    transform: scaleX(0.8) scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
}
