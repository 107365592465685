.loader-in-file-card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-universal-secondary-e);
  opacity: 0.75;
  cursor: default;
  z-index: var(--z-index-3);
}
.loader-in-file-card svg {
  width: 50px;
  height: 50px;
}

.loader-over-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-universal-secondary-e);
  z-index: var(--z-index-4plus2);
  transform: rotate(180deg);
}
