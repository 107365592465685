.legado-menubar {
  background: var(--page-color-context-background-primary);
  border-bottom: 1px solid var(--color-universal-secondary-d);
  border-top: 1px solid var(--color-universal-secondary-d);
  box-shadow: 1px 0 2px var(--color-universal-secondary-d);
  box-shadow: -1px 0 2px var(--color-universal-secondary-d);
}

/*TODO: Convert to using component specific css variables like ProfileButton instead of targeting legado-menubar by theme https://dev.azure.com/secure-the-file/Application/_workitems/edit/15774*/
.lifestage .legado-menubar {
  background-color: var(--navbar-color-interaction-background-primary);
}

.one-x .legado-menubar {
  width: 100%;
  max-width: calc(var(--page-max-width) + var(--page-margin) * 2);
  padding-right: var(--page-margin);
  margin-left: var(--page-margin);
}

.one-x .container {
  margin-left: var(--page-margin);
}

.one-x .legado-menubar svg {
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
}

.legado-menubar + .legado-menubar {
  border-top: none;
  box-shadow: none;
}

.legado-menubar .banner {
  display: flex;
  align-items: center;
  font-weight: var(--fw-bold);
}

.legado-menubar .banner > div {
  position: relative;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

.legado-menubar .bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.legado-menubar .banner > a:hover {
  text-decoration: none;
}

.nav-menu-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.legado-menu-container .nav-menu-top-bar {
  margin-bottom: 0px;
}

.menuitem {
  transition: all var(--animation-timing-default);
  font-weight: var(--fw-s);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.vault-menu .menuitem {
  width: 100%;
  display: block;
  padding: 10px 0;
}

.menuitem > a,
.menuitem > nav,
.menuitem > span {
  color: var(--color-universal-secondary-b);
  font-size: var(--fs-s);
  font-weight: var(--fw-regular);
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
}

.menuitem > span:last-child {
  padding-right: 8px;
}
.menuitem > span.icon-box:last-child {
  padding-right: 10px;
}

.menuitem .icon-box {
  margin-left: 13px;
  margin-right: 10px;
}
.menuitem .icon-box svg {
  width: 100%;
  height: auto;
}

.main-menu {
  margin-right: 40px;
}

.menuitem-box {
  position: relative;
}

.one-x .menuitem-box {
  position: relative;
  margin-right: 20px;
  border: 1px solid #dedfe3;
  border-radius: var(--border-radius-s);
}

.menuitem-wrapper {
  display: flex;
  height: 31px;
  padding: 4px 0 0px 10px;
}

.one-x .menuitem-wrapper {
  display: flex;
  height: 31px;
  padding: 0px 0 0px 10px;
}

.menuitem-wrapper:hover {
  background: var(--component-color-interaction-background-primary-hover);
  border-radius: var(--border-radius-s);
}

.menuitem-box.expanded .menuitem-wrapper {
  background: var(--component-color-interaction-background-primary-hover);
  border-radius: var(--border-radius-s);
}

.main-menu .menuitem-box:first-child {
  padding-right: 0;
}
.main-menu .menuitem-box:last-child {
  border-right: 1px solid transparent;
}

.one-x .main-menu .menuitem-box:last-child {
  border-right: 1px solid #dedfe3;
}

.legado-logo {
  height: 45px;
  width: 125px;
}

.legado-logo svg {
  fill: var(--color-action-logo-link-base);
  stroke: none;
  width: 100%;
  height: auto;
  margin-left: 53px;
}

.legado-logo .logo-text {
  font-size: var(--fs-s);
  white-space: nowrap;
}

.nav-menu-top-bar .legado-logo svg {
  margin-left: 0;
}

.vault-menu {
  font-size: var(--fs-s);
  color: var(--link-color-action-text-primary);
  position: relative;
}

.vault-menu .menu-bar-vault-box {
  z-index: var(--z-index-4);
  background-color: var(--color-universal-natural-grey);
  border-radius: var(--border-radius-s);
  position: absolute;
  width: 250px;
  border: 1px solid var(--divider-color-context-background-lighter);
  padding: 0px;
  box-shadow: var(--shadow-0);
  right: 0px;
}

.vault-menu .menuitem-box {
  background-color: var(--navbar-color-interaction-background-primary);
  border-bottom: 1px solid var(--divider-color-context-background-lighter);
  padding: 0;
}
.vault-menu .menuitem-box:first-child {
  border-radius: var(--border-radius-s) var(--border-radius-s) 0 0;
  padding-top: 10px;
}
.vault-menu .menuitem-box:last-child {
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
  border-bottom: none;
  padding-bottom: 10px;
}
.vault-menu .menuitem-box:last-child.expanded {
  padding-bottom: 0px;
}

.vault-menu .icon {
  margin: 0 17px;
}
.vault-menu .icon svg {
  width: 16px;
  height: 16px;
  fill: var(--color-universal-primary-base);
  stroke: var(--color-universal-primary-base);
}

.vault-menu .icon-box {
  float: right;
}

.background-navbar {
  background-color: var(--navbar-color-interaction-background-primary);
}

.back-to-link-box {
  display: inline-flex;
  align-items: center;
  margin: 55px 0;
}

.back-to-link-icon {
  width: var(--icon-sizing-s);
  margin-right: 15px;
}

.back-to-link-icon svg {
  display: inline-block;
  width: 100%;
  height: auto;
  stroke: var(--link-color-action-text-primary);
}

.back-to-link {
  color: var(--link-color-action-text-primary);
  border-bottom: 2px dotted var(--link-color-action-text-primary);
  font-weight: var(--fw-medium);
}

.menu-button-closed {
  background-color: var(--button-color-action-background-primary) !important;
}

.menu-button-closed span {
  color: white !important;
}

.mainMenuDesktop-bar .hide-on-mobile-only {
  width: 100%;
}

@media (max-width: 1229px) {
  .back-to-link-box {
    margin: 0;
  }

  .one-x .bar.hide-on-desktop-only.background-navbar {
    width: 0px;
    height: 0px;
  }

  .legado-menubar .bar {
    padding: 20px 0;
    margin: 0;
    width: inherit;
  }

  .one-x .legado-menubar .bar {
    width: 100%;
    max-width: 2110px;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 20px;
  }

  .nav-menu-top-bar {
    margin-top: 14px;
    margin-bottom: 15px;
  }
  .menuitem-wrapper {
    height: 30px;
    padding: 0;
    margin: 0;
    border: none;
  }

  .menuitem-box.expanded .menuitem-wrapper {
    background: var(--navbar-color-interaction-background-primary);
    border-radius: var(--border-radius-s);
  }

  .one-x .menuitem-box.expanded .menuitem-wrapper {
    margin-bottom: 0px;
  }

  .menuitem-wrapper .icon-box {
    padding-top: var(--spacing-xx-s);
  }

  .one-x .menuitem-wrapper .icon-box {
    padding-top: 0px;
  }

  .vault-menu .menuitem-box:not(.expanded) {
    padding-bottom: 10px;
  }

  .vault-menu .menuitem {
    width: 100%;
    display: block;
  }

  .menuitem-wrapper:hover {
    background: var(--color-universal-primary-background);
    border-radius: var(--border-radius-s);
  }

  .legado-menubar .container {
    display: flex;
    gap: 15px;
    flex-direction: row-reverse;
  }

  .one-x .legado-menubar .container {
    display: flex;
    gap: 0px;
    flex-direction: row;
  }
}
@media (max-width: 1229px) {
  .mainMenuDesktop-bar .hide-on-mobile-only {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobile-top-bar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-2);
  }

  .nav-menu-top-bar {
    margin-top: 12px;
  }

  .one-x .menuitem-box {
    margin-right: 12px;
  }

  .one-x .legado-menubar .bar {
    width: 100%;
    max-width: 2110px;
    padding-left: 10px;
    padding-right: 0px;
  }
  .one-x .legado-menubar .search-bar {
    align-self: flex-end;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 2110px;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .one-x .search-bar .search-icon {
    display: inline-block;
    width: var(--icon-sizing-xs);
    height: var(--icon-sizing-xs);
    pointer-events: none;
  }

  .menuitem-box.expanded.false {
    border: 1px solid transparent;
  }
  .mainMenuDesktop-bar .menuitem-box {
    border: 1px solid transparent;
  }
}
@media (max-width: 428px) {
  .legado-menubar .container {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

.legado-menu-container {
  background-color: var(--navbar-color-interaction-background-primary);
  padding: 10px 30px 15px 30px;
  width: 100vw;
  box-shadow: 0px 1px 0px #e9e7e6;
}

.legado-menu .search-bar {
  margin-top: 20px;
}

.legado-menu-container .menu-button span {
  font-size: var(--fs-base);
  font-weight: 500;
}

.one-x .menuitem-box.disabled {
  background-color: #48475c;
  color: white;
  border-radius: var(--border-radius-s);
}

.one-x .menuitem-wrapper.disabled span {
  color: #ffffff;
}

.one-x .legado-menubar .search-bar {
  align-self: flex-end;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-width: 2110px;
  padding-left: 32px;
  padding-right: 32px;
  margin-right: 20px;
  margin-top: 20px;
}

.one-x .menuitem-box.false {
  height: 29px;
}

.one-x .menuitem-box.disabled {
  height: 29px;
}

.one-x .legado-menubar.adviser {
  border-bottom: 0;
  box-shadow: 0px 0 0px var(--color-universal-secondary-d);
}

.legado-menubar.oneX-hub {
  margin-top: 14px;
  border-top: none;
  border-bottom: none;
  box-shadow: none;
}

.oneX-hub .container .mainMenuDesktop-bar {
  padding-left: 0px;
}
.oneX-hub .container {
  padding-left: 0px;
}

.one-legacy .mainMenuDesktop-bar .hide-on-tablet-only {
  width: 100%;
}
