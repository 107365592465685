.filter-wrapper .form-select {
  width: 150px;
  cursor: inherit;
}

.filter-wrapper .select-label {
  margin-bottom: 5px;
  font-weight: var(--fw-medium);
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  padding: 0px;
  color: var(--form-color-interaction-text-label-primary);
}

.filter-wrapper .select-label:hover {
  background-color: unset;
}

.form-select-wrapper:hover .input {
  background: var(--component-color-interaction-background-primary-hover);
}

.form-select-wrapper:hover,
.input:hover {
  outline: 1px solid var(--search-color-interaction-border-primary-focus);
}

.filter-wrapper .form-select .form-select-wrapper {
  position: relative;
  width: fit-content;
  border-radius: var(--form-radius);
}

.form-select-wrapper {
  display: flex;
  align-items: center;
  width: 165px;
}

.filter-wrapper .form-select .input {
  border-radius: var(--form-radius);
  border-top-color: var(--form-color-interaction-border-primary);
  border: solid 1px var(--form-color-interaction-border-primary);
  cursor: pointer;
  display: flex;
  padding: 6px 15px 6px 15px;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 35px;
  font-weight: var(--fw-regular);
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  text-overflow: ellipsis;
}

.filter-wrapper .form-select .input:focus,
input:active {
  outline: 1px solid var(--form-color-interaction-border-primary-focus);
  background: none;
  color: black;
}

.filter-wrapper.content-on .form-select input {
  background-color: var(--form-color-interaction-background-primary);
}

.filter-wrapper.content-on .form-select .radio-input {
  color: var(--color-secondary-i);
  background-color: var(--color-universal-natural-white);
}

.filter-wrapper .form-select .open-close-svg {
  right: var(--spacing-xxx-s);
  position: absolute;
}

.filter-wrapper .form-select .select-content {
  z-index: var(--z-index-4);
  position: absolute;
  background-color: var(--color-universal-natural-white);
  top: 100%;
  width: 270px;
  right: unset;
  border: 1px solid var(--divider-color-context-background-primary);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 2px 5px var(--divider-color-context-background-lighter);
  max-height: 650px;
  overflow-y: auto;
}

.filter-wrapper .form-select .select-content label {
  border-bottom: 1px solid var(--divider-color-context-background-primary);
  padding: 15px;
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.filter-wrapper .form-select .select-content label span {
  font-size: inherit;
  line-height: inherit;
}

.filter-wrapper .form-select .select-content label:hover {
  background: var(--component-color-interaction-background-primary-hover);
}

.filter-wrapper .form-select .select-content .checkbox,
.filter-wrapper .radio-container input {
  width: 18px;
  height: 18px;
  margin-right: 21px;
}

.filter-wrapper .radio-container input.input::before {
  transform: scale(0.06);
}

.filter-wrapper .radio-container input.radio-input:checked::before {
  transform: scale(0.06);
}

.filter-wrapper .form-select .select-content .checkbox svg {
  height: 90%;
  width: auto;
}

.filter-wrapper .form-select .form-select-wrapper svg {
  stroke: var(--color-universal-secondary-b);
}

.filter-wrapper .form-select {
  width: 100%;
}

.form-select.document-hub-status-filter {
  margin-left: var(--spacing-x-s);
  margin-right: var(--spacing-m);
}

@media (max-width: 1229px) {
  .filter-wrapper .form-select .form-select-wrapper {
    width: 100%;
  }
}
