.additional-assistance-wrapper .text-area .input-box:focus {
  background: var(--form-color-interaction-background-primary);
}

.additional-assistance-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.additional-assistance-checkbox {
  display: flex;
  align-items: flex-start;
}

.text-area.additional-assistance-input {
  height: 117px;
  max-width: 740px;
  margin-bottom: var(--spacing-xxxx-l);
}

.additional-assistance-label {
  font-size: var(--fs-base);
  line-height: var(--fs-xxl);
  font-weight: var(--fw-medium);
  margin-left: var(--spacing-m);
  background-color: var(--page-color-context-background-primary);
}

.text-area.additional-assistance-input .label {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-medium);
  background-color: var(--page-color-context-background-primary);
}

.text-area.additional-assistance-input textarea::placeholder {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-regular);
}

.additional-assistance-wrapper .input-wrapper.error {
  border-color: var(--component-color-feedback-danger);
}

.additional-assistance-input .text-area-top-note {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  font-weight: var(--fw-regular);
}
@media only screen and (max-width: 519px) {
  .text-area.additional-assistance-input {
    height: 117px;
    max-width: 740px;
    margin-bottom: 100px;
  }
}
