.top-bar-content {
  display: flex;
  flex-direction: row;
  padding: 0px 50px 18px 50px;
  justify-content: space-between;

  @media screen and (max-width: 1230px) {
    padding: 0px 35px 18px 35px;
  }
}

.top-bar-content .logo-container {
  width: 38px;
  height: 40px;
}

.top-bar-content .top-bar-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.top-bar-content .top-bar-buttons-container svg {
  width: 40px;
  height: 40px;
}

.top-bar-content .top-bar-buttons-container .search-button {
  stroke: var(--search-color-interaction-background-primary);
}

.top-bar-content .top-bar-buttons-container .hamburger-button {
  stroke: var(--page-color-context-background-primary);
  fill: var(--page-color-context-background-primary);
}
