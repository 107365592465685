/*
the left nav bar has a fixed width of 250px
except for where this component is referenced (where the logo with text items is used)
the design paddings are set to 44px on each side
however the full width of the left nav bar should be 305px for this component
this means that the width of this component needs to be set
to 217px in order to achieve the designs 305px width left nav bar
*/
.logo-with-text {
  width: 217px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 44px;
  gap: 20px;
}

.logo-with-text .logo-container {
  width: 38px;
  height: 40px;
  align-self: flex-start;
}

.logo-with-text h1 {
  font-weight: var(--fw-bold);
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  color: var(--lozenge-color-feedback-text-info);
  margin: 0;
}

.logo-with-text .content-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.logo-with-text .content-text p {
  font-weight: var(--fw-regular);
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  color: var(--lozenge-color-feedback-text-info);
  margin: 0;
}
