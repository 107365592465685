.logout-link {
  font-size: var(--fs-s);
  font-weight: var(--fw-regular);
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 24px;
  white-space: nowrap;
}

.logout-link span svg {
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
}

@media (max-width: 1229px) {
  .logout-link {
    font-size: var(--fs-s);
    font-weight: var(--fw-regular);
    border-left: 1px transparent var(--divider-color-context-background-primary);
    padding-left: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    height: 24px;
    margin-top: 5px;
  }
}
