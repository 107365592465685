.loadingspinner {
  border: 1px solid var(--color-universal-primary-base);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: loadingspinnerrotation 1s linear infinite;
}

@keyframes loadingspinnerrotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
