.field-error {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--component-color-feedback-danger);
  font-size: var(--fs-s);
  font-weight: var(--fw-light);
}

.field-error--with-margin-top {
  margin-top: 14px;
}

.field-error label {
  margin-left: 3px;
  font-weight: var(--fw-medium);
}

.field-error svg {
  stroke: none;
}
