.legado-footer {
  color: var(--color-universal-natural-white);
  padding: 30px 0;
}

.one-x .legado-footer {
  background-color: #f9f9f9;
  float: left;
  max-width: 1490px;
  padding-right: var(--page-margin);
}

.legado-footer > div {
  font-size: var(--fs-s);
  line-height: var(--lh-m);
  font-weight: var(--fw-light);
  text-align: center;
}

.barclaysFooter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.barclaysFooterMobile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.moneyhub-footer-mobile {
  background-color: #c0c0c0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  margin-top: auto;
}

.logoBottomRight {
  position: absolute;
  right: 50px;
  bottom: 38px;
}

.legado .logoFooter {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 70px 50px 40px 0px;
}

@media (max-width: 768px) {
  .legado .logoFooter {
    margin: 30px 0px 50px 0px;
    justify-content: center;
  }
}

.logoTFEFooter {
  position: relative;
  margin-top: 120px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .logoTFEFooter {
    position: relative;
    display: flex;
    margin: 70px 50px 40px 70px;
  }

  .logoBottomRight {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}
