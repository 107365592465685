.document-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.document-details .document-details__meta-data {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

.document-details .document-details__meta-data small {
  font-size: var(--fs-base);
  line-height: 24px;
}

.document-details .document-details__file-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}
.document-details__file-name-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 510px;
  @media (max-width: 744px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
  }
}

.document-details .document-details__wizard-link {
  align-content: flex-end;
}

.document-details .document-details__wizard-link:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.document-details .document-details__wizard-link button {
  padding: 0px;
  text-decoration: underline;
  font-size: var(--fs-s);
  color: var(--link-color-action-text-primary);
}
.document-details .document-details__wizard-link button:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

@media (max-width: 500px) {
  .document-details {
    flex-direction: column;
    align-self: self-start;
  }
  .document-details .document-details__meta-data {
    align-items: unset;
  }

  .document-details .hide-for-mobile {
    display: none;
  }
}

@media (min-width: 501px) {
  .document-details .show-for-mobile {
    display: none;
  }
}
