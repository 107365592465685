.links-collection-container {
  margin: 44px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.links-collection-container .link-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
}

.links-collection-container .link-container a,
.links-collection-container .link-container button {
  font-size: var(--fs-s);
  font-weight: var(--fw-regular);
  line-height: var(--lh-s);
  color: var(--navbar-color-interaction-text);
  padding: 0;
  text-align: left;
  &:not(.icon-link) {
    border-bottom: 1px solid var(--navbar-color-interaction-text);
  }
}

.links-collection-container
  .link-container:not(.icon-link-container)
  svg
  g
  path {
  stroke: var(--navbar-color-interaction-text);
}
.links-collection-container
  .link-container:not(.link-container--no-fill):not(.icon-link-container)
  svg
  g
  path {
  fill: var(--navbar-color-interaction-text);
}

.links-collection-container .link-container .link:hover:not(.icon-link) {
  color: var(--navbar-color-interaction-text);
  border-bottom: 1px solid var(--navbar-color-interaction-text);
}

.links-collection-container .link-container--fixed-bottom {
  position: fixed;
  bottom: 50px;
}
