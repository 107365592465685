.tooltip {
  position: relative;
}

.tooltip-message {
  position: absolute;
  left: 50%;
  transform: translateX(calc(-50% - 80px));
  z-index: 1001;
  width: auto;
  max-width: 90vw;
  display: block;
  padding: 10px;
  background-color: var(--color-universal-secondary-a);
  border-radius: var(--border-radius-s);
  color: var(--color-universal-natural-white);
  margin-bottom: 0;
  font-weight: var(--fw-medium);
  font-size: var(--fs-xs);
}
.tooltip-message .tooltip-title {
  font-weight: var(--fw-bold);
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.tooltip-message ul {
  color: var(--color-universal-natural-white);
}
.tooltip-message li {
  list-style-type: disc;
  color: var(--color-universal-natural-white);
  font-weight: var(--fw-medium);
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
}

.direction-down .tooltip-message {
  bottom: unset;
  top: calc(100% + 10px);
}

.tooltip.open:not(.tooltip-hidden):not(.direction-down):after {
  position: absolute;
  width: 20px;
  height: 14px;
  left: 50%;
  transform: translateX(-50%);
  top: -12px;
  content: "";
  border-top: solid 12px var(--color-secondary-i);
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
}

.tooltip.open:not(.tooltip-hidden).direction-down:after {
  position: absolute;
  width: 20px;
  height: 14px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  content: "";
  border-bottom: solid 12px var(--color-secondary-i);
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
}

.infoButton {
  padding: 0;
}

.infoButton svg {
  height: 24px;
  width: 24px;
}

.oneX .tooltip-message {
  width: auto;
  left: -294%;
}

@media (max-width: 767px) {
  .tooltip-message {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
  }
}
