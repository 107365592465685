.left-navigation {
  width: 250px;
  min-width: 250px;
  background: var(--navbar-color-interaction-background-primary);
}

.left-navigation.expanded {
  min-width: 305px;
}

/* desktop screen size starts at 1230px, setting the left nav shift to 1230px breaks the page and hides items so it needs to be set to 1229px */
@media (max-width: 1229px) {
  .left-navigation {
    position: fixed;
    z-index: var(--z-index-3plus1);
    height: 100%;
  }
}

@media (min-width: 1230px) {
  .cover-and-left-nav {
    min-height: 100vh;
    display: flex;
    height: auto;
  }
}

.cover-and-left-nav .cover-screen {
  z-index: var(--z-index-3);
  background-color: var(--modal-background);
}

.cover-and-left-nav .cover-screen + .left-nav-content {
  height: 100vh;
}

.left-nav-content {
  position: relative;
  top: 0px;
  z-index: var(--z-index-3plus1);
  background-color: var(--navbar-color-interaction-background-primary);
}

.left-nav-content--sticky {
  position: sticky;
  top: 44px;
}

/* desktop screen size starts at 1230px, setting the left nav shift to 1230px breaks the page and hides items so it needs to be set to 1229px
   Also, specifically here, the padding-top is set to 1px to fix the issue of the left nav top not stretching to the top of the screen
*/
@media screen and (max-width: 1229px) {
  .left-nav-content {
    padding-top: 1px;
  }
}

.left-nav-content nav {
  overflow-y: auto;
}

@media (max-width: 767px) {
  .left-navigation.menu-collapsed {
    max-width: 18%;
    display: none;
  }
}

.left-navigation.menu-collapsed .logo-wrapper .icon-box {
  height: 32px;
}

.left-nav-content .menu-expand-button {
  padding: 0;
  display: flex;
  margin-top: var(--spacing-s);
  width: 100%;
  justify-content: flex-end;
}

.left-navigation.menu-collapsed .left-nav-content .menu-expand-button {
  justify-content: start;
  margin-top: 45px;
}

.left-nav-content .logo-wrapper {
  padding: var(--spacing-s);
  border-bottom: 1px var(--divider-color-context-background-lighter) solid;
}

.left-nav-content .logo-wrapper .icon-box {
  height: 35px;
  width: auto;
}

.left-nav-content .icon-box svg {
  height: 100%;
  width: 100%;
}

.left-nav-content .logo-wrapper .logo-text {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-light);
  padding-left: 46px;
  margin-bottom: 0;
}

.left-nav-content .navigation-list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xx-s);
  margin-top: var(--spacing-xx-s);
}

.left-nav-content > nav > .navigation-list {
  margin-top: var(--spacing-s);
}

.navigation-list .navigation-link {
  width: fit-content;
  display: grid;
  grid-template-columns: var(--icon-sizing-s) var(--icon-sizing-s) minmax(
      0,
      max-content
    );
  gap: var(--spacing-xx-s);
  align-items: center;
  padding: 5px 12px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin-right: var(--spacing-s);
}

.left-navigation.menu-collapsed .navigation-link {
  grid-template-columns: 1fr;
  padding: 5px 12px 5px var(--spacing-s);
}

.navigation-list .navigation-link:hover {
  background-color: var(--component-color-interaction-background-primary-hover);
}

.navigation-list .navigation-link.active-link {
  background-color: var(--navbar-color-interaction-background-active);
}

.the-family-elephant .navigation-list .navigation-link.active-link {
  background-color: var(--navbar-color-interaction-background-active);
}

.left-navigation.menu-collapsed .navigation-list .chevron-icon,
.left-navigation.menu-collapsed .navigation-list .navigation-link-name {
  display: none;
}

.left-navigation.menu-collapsed .navigation-list .link-icon {
  grid-column: 1/2;
}

.navigation-list .navigation-link.active-link .navigation-link-name {
  font-weight: var(--fw-regular);
  color: var(--navbar-color-interaction-text);
}

.navigation-link .navigation-link-name {
  grid-column: 3/4;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  max-width: 100%;
  text-align: start;
}

.navigation-list .navigation-link .link-icon,
.navigation-list .navigation-list .chevron-icon {
  grid-column: 2/3;
}

.navigation-list .navigation-link .icon-box {
  width: var(--icon-sizing-xs);
  height: var(--icon-sizing-xs);
  justify-self: center;
}

.navigation-list .navigation-link .icon-box svg {
  width: 100%;
  height: auto;
  fill: var(--navbar-color-interaction-text);
  stroke: var(--navbar-color-interaction-text);
}

.navigation-list .navigation-link.active-link .icon-box svg {
  fill: var(--navbar-color-interaction-text);
  stroke: var(--navbar-color-interaction-text);
}

.menu-divider {
  margin: 20px 20px 10px 20px;
}

.moneyhub-sidebar {
  background-color: #3b3b3b;
}

.TFE-left-logo {
  width: 188px;
  margin-right: 25px;
}

.logo-wrapper.svg-no-rules .svg svg {
  fill: none;
  stroke: none;
}

.left-navigation .logo-with-title-container .title {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-medium);
}
