@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(../Helvetica/Helvetica-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../Helvetica/Helvetica.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(../Helvetica/Helvetica-Bold.ttf) format("truetype");
  font-weight: 700;
}
