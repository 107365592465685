:root {
  --bp-desktop: 1230px;
  --bp-tablet: 768px;
  --bp-mobile: 767px;
  --spacing-xxx-s: 5px;
  --spacing-xx-s: 10px;
  --spacing-x-s: 15px;
  --spacing-s: 20px;
  --spacing-m: 30px;
  --spacing-l: 40px;
  --spacing-x-l: 50px;
  --spacing-xx-l: 60px;
  --spacing-xxx-l: 70px;
  --spacing-xxxx-l: 80px;
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 99;
  --z-index-3plus1: 100;
  --z-index-4: 999;
  --z-index-4plus1: 1000;
  --z-index-4plus2: 1001;
}

.legado {
  --bp-desktop: 1230px;
  --bp-tablet: 768px;
  --bp-mobile: 767px;
  --spacing-xxx-s: 5px;
  --spacing-xx-s: 10px;
  --spacing-x-s: 15px;
  --spacing-s: 20px;
  --spacing-m: 30px;
  --spacing-l: 40px;
  --spacing-x-l: 50px;
  --spacing-xx-l: 60px;
  --spacing-xxx-l: 70px;
  --spacing-xxxx-l: 80px;
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 99;
  --z-index-3plus1: 100;
  --z-index-4: 999;
  --z-index-4plus1: 1000;
  --z-index-4plus2: 1001;
}

.legado label {
  font-weight: 500;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.disabled .click-me {
  cursor: default;
}
.click-me {
  cursor: pointer;
}
.icon-box {
  display: inline-flex;
}
.icon-box.icon-l svg {
  width: 32px;
  height: 32px;
}
.icon-box.icon-m svg {
  width: 24px;
  height: 24px;
}

button.icon-box {
  padding: 0;
}

.disabled {
  opacity: 0.5;
  cursor: default !important;
  user-select: none;
  pointer-events: none;
}

.display-inline-block {
  display: inline-block;
}
.position-relative {
  position: relative;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-600 {
  font-weight: 600 !important;
}

@keyframes come-in {
  0% {
    opacity: 0;
    transform: scaleY(0.8) scaleX(0.8);
  }
  50% {
    opacity: 0.7;
    transform: scaleY(1.1) scaleX(1.1);
  }
  100% {
    opacity: 1;
    transform: scaleY(1) scaleX(1);
  }
}
.floatRight {
  float: right;
}
.flex {
  display: flex !important;
}
.inline-flex {
  display: inline-flex !important;
}

.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-110 {
  flex: 1 1 0px;
}

.flex-basis-100 {
  flex-basis: 100% !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}

.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-center {
  align-self: center;
}
.gap-0 {
  gap: 5px;
}
.gap-1 {
  gap: 10px;
}
.gap-2 {
  gap: 15px;
}
.gap-3 {
  gap: 20px;
}
.gap-4 {
  gap: 30px;
}
.gap-5 {
  gap: 40px;
}
.gap-6 {
  gap: 50px;
}
.gap-7 {
  gap: 60px;
}
.gap-8 {
  gap: 70px;
}
.gap-9 {
  gap: 80px;
}

.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}

.m-auto {
  margin: auto;
}

.no-margin {
  margin: 0 !important;
}

.m-0 {
  margin: 5px;
}
.m-1 {
  margin: 10px;
}
.m-2 {
  margin: 15px;
}
.m-3 {
  margin: 20px;
}
.m-4 {
  margin: 30px;
}
.m-5 {
  margin: 40px;
}
.m-6 {
  margin: 50px;
}
.m-7 {
  margin: 60px;
}
.m-8 {
  margin: 70px;
}
.m-9 {
  margin: 80px;
}

.mb-0 {
  margin-bottom: 5px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 15px;
}
.mb-3 {
  margin-bottom: 20px;
}
/* We should remove mb-4 and use only mb-30 everywhere, same for other values */
.mb-4 {
  margin-bottom: 30px !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-5 {
  margin-bottom: 40px;
}
.mb-6 {
  margin-bottom: 32px;
}
.mb-7 {
  margin-bottom: 60px;
}
.mb-8 {
  margin-bottom: 70px;
}
.mb-9 {
  margin-bottom: 80px;
}
.mb-10 {
  margin-bottom: 100px;
}
.mb-15 {
  margin-bottom: 150px;
}

.ml-auto {
  margin-left: auto;
}
.ml-0 {
  margin-left: 5px;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 15px;
}
.ml-3 {
  margin-left: 20px;
}
.ml-4 {
  margin-left: 30px;
}
.ml-5 {
  margin-left: 40px;
}
.ml-6 {
  margin-left: 50px;
}
.ml-7 {
  margin-left: 60px;
}
.ml-8 {
  margin-left: 70px;
}
.ml-9 {
  margin-left: 80px;
}

.mr-0 {
  margin-right: 5px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 15px;
}
.mr-3 {
  margin-right: 20px;
}
.mr-4 {
  margin-right: 30px;
}
.mr-5 {
  margin-right: 40px;
}
.mr-6 {
  margin-right: 50px;
}
.mr-7 {
  margin-right: 60px;
}
.mr-8 {
  margin-right: 70px;
}
.mr-9 {
  margin-right: 80px;
}

.mt-0 {
  margin-top: 5px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 15px;
}
.mt-3 {
  margin-top: 20px;
}
.mt-4 {
  margin-top: 30px;
}
.mt-5 {
  margin-top: 40px;
}
.mt-6 {
  margin-top: 50px;
}
.mt-7 {
  margin-top: 60px;
}
.mt-8 {
  margin-top: 70px;
}
.mt-9 {
  margin-top: 80px;
}

.p-auto {
  margin: auto;
}
.no-padding {
  padding: 0;
}
.p-0 {
  padding: 5px;
}
.p-1 {
  padding: 10px;
}
.p-2 {
  padding: 15px;
}
.p-3 {
  padding: 20px;
}
.p-4 {
  padding: 30px;
}
.p-5 {
  padding: 40px;
}
.p-6 {
  padding: 50px;
}
.p-7 {
  padding: 60px;
}
.p-8 {
  padding: 70px;
}
.p-9 {
  padding: 80px;
}

.pb-0 {
  padding-bottom: 5px;
}
.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 15px;
}
.pb-3 {
  padding-bottom: 20px;
}
.pb-4 {
  padding-bottom: 30px;
}
.pb-5 {
  padding-bottom: 40px;
}
.pb-6 {
  padding-bottom: 50px;
}
.pb-7 {
  padding-bottom: 60px;
}
.pb-8 {
  padding-bottom: 70px;
}
.pb-9 {
  padding-bottom: 80px;
}

.pl-0 {
  padding-left: 5px;
}
.pl-1 {
  padding-left: 10px;
}
.pl-2 {
  padding-left: 15px;
}
.pl-3 {
  padding-left: 20px;
}
.pl-4 {
  padding-left: 30px;
}

.pl-5 {
  padding-left: 40px;
}
.pl-6 {
  padding-left: 50px;
}
.pl-7 {
  padding-left: 60px;
}
.pl-8 {
  padding-left: 70px;
}
.pl-9 {
  padding-left: 80px;
}

.pr-0 {
  padding-right: 5px;
}
.pr-1 {
  padding-right: 10px;
}
.pr-2 {
  padding-right: 15px;
}
.pr-3 {
  padding-right: 20px;
}
.pr-4 {
  padding-right: 30px;
}
.pr-5 {
  padding-right: 40px;
}
.pr-6 {
  padding-right: 50px;
}
.pr-7 {
  padding-right: 60px;
}
.pr-8 {
  padding-right: 70px;
}
.pr-9 {
  padding-right: 80px;
}

.pt-0 {
  padding-top: 5px;
}
.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 15px;
}
.pt-3 {
  padding-top: 20px;
}
.pt-4 {
  padding-top: 30px;
}
.pt-5 {
  padding-top: 40px;
}
.pt-6 {
  padding-top: 50px;
}
.pt-7 {
  padding-top: 60px;
}
.pt-8 {
  padding-top: 70px;
}
.pt-9 {
  padding-top: 80px;
}

.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}

/* GRID START */

.container {
  width: 100%;
  max-width: calc(var(--page-max-width) + var(--page-margin) * 2);
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  margin: 0 auto;
}

.main.content.container.mb-5.onex {
  width: 100%;
  max-width: 2110px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 20px;
  margin: 0 auto;
}

.main.content.container.mt-5.onex {
  width: 100%;
  max-width: 2110px;
  padding-right: 30px;
  margin-right: 20px;
  margin: 0 auto;
}

@media (min-width: 2600px) {
  .main.content.container.mb-5.onex {
    width: 100%;
    max-width: 1950px;
    padding-left: 30px;
    padding-right: 200px;
    margin-right: var(--page-margin);
    margin: 0 auto;
    float: left;
  }

  .main.content.container.mt-5.onex {
    width: 100%;
    max-width: 1950px;
    padding-left: 30px;
    padding-right: var(--page-margin);
    margin: 0 auto;
    float: left;
  }
}

.col {
  flex: 1 0 0%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1230px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (max-width: 767px) {
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.show-on-mobile-only {
  display: none;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.hide {
  visibility: hidden !important;
}

@media only screen and (max-width: 767px) {
  .hide-on-mobile-only,
  .hide-on-mobile-and-down {
    display: none !important;
  }
  .show-on-mobile-only {
    display: block;
  }
}

@media only screen and (max-width: 1229px) {
  .hide-on-tablet-and-down {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .hide-on-tablet-and-up {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1229px) {
  .hide-on-tablet-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1230px) {
  .hide-on-desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1230px) {
  .show-on-desktop {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1229px) {
  .show-on-tablet {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .show-on-mobile {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) {
  .show-on-tablet-and-up {
    display: block !important;
  }
}

@media only screen and (max-width: 1229px) {
  .show-on-tablet-and-down {
    display: block !important;
  }
}

.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.section-spacing {
  border-top: 1px solid var(--divider-color-context-background-primary);
}

@media only screen and (max-width: 1229px) {
  .section-spacing {
    padding-top: var(--spacing-l);
    margin-top: var(--spacing-l);
  }
}

@media only screen and (max-width: 767px) {
  .section-spacing {
    padding-top: var(--spacing-m);
    margin-top: var(--spacing-m);
  }
}

.required-asterisk {
  color: red;
  margin-right: 2px;
}
