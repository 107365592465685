input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-universal-primary-background);
  margin: 0;
  width: 100%;
  height: 100%;
  color: var(--color-universal-secondary-b);
  border: 0.15em solid var(--color-universal-secondary-b);
  border-radius: 0.25em;
  transform: translateY(-0.075em);
  box-shadow: 0.75px 0.75px 0px var(--color-universal-primary-grey);
  box-shadow: inset 0.75px 0.75px 0px var(--color-universal-primary-grey);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  clip-path: polygon(11% 41%, 0 55%, 42% 91%, 100% 15%, 86% 5%, 41% 67%);
  box-shadow: inset 1em 1em var(--color-universal-secondary-b);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/* removes border set by browser on focus */
.checkbox input[type="checkbox"]:focus {
  outline: none;
}

/* Enable outline when 'focus-enabled' class is present */
.checkbox.focus-enabled input[type="checkbox"]:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.checkbox.disabled {
  opacity: 30%;
}

.checkbox {
  width: 30px;
  height: 30px;
}
