.terms-and-conditions-text {
  display: flex;
  flex-direction: column;

  a:focus {
    outline: 2px solid var(--color-input-outline-focus);
    outline-offset: 3px;
  }
}

.terms-and-conditions-text-error {
  color: var(--component-color-feedback-danger);
}
