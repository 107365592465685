.uploaded-document {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
}

.uploaded-document__details {
  display: flex;
  gap: 20px;
}

.uploaded-document__thumbnail-and-names {
  display: flex;
  gap: 20px;
}

.uploaded-document__thumbnail {
  align-self: center;
}

.uploaded-document__document-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  font-size: 20px;
}

.uploaded-document__document-name .uploaded-document__document-name-label {
  font-size: 18px;
}
.uploaded-document__document-name-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  @media (max-width: 744px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
  }
}

.uploaded-document__document-name p,
.uploaded-document__document-category p {
  font-size: 20px;
  margin: 0px;
}

.uploaded-document__document-category {
  font-size: 20px;
  margin-right: 40px;
}

.uploaded-document__document-category
  .uploaded-document__document-category-value {
  font-size: 16px;
  font-weight: 600;
}

.uploaded-document__document-name button,
.uploaded-document__document-category button {
  text-align: left;
  padding: 0px;
  text-decoration: underline;
  font-size: var(--fs-s);
  color: var(--link-color-action-text-primary);
}

.uploaded-document__document-name button:focus-visible,
.uploaded-document__document-category button:focus-visible {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.uploaded-document__category-container {
  margin-top: 20px;
}
