@font-face {
  font-family: "Basier Circle";
  src: local("Basier Circle"),
    url(../BasierCircle/BasierCircle-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Basier Circle";
  src: local("Basier Circle"),
    url(../BasierCircle/BasierCircle-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Basier Circle";
  src: local("Basier Circle"),
    url(../BasierCircle/BasierCircle-Bold.ttf) format("truetype");
  font-weight: 700;
}
