.document-page-overview-display-list-header {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: var(--fw-bold);
}

.document-page-overview-text {
  margin-bottom: 20px;
}

.document-page-overview-button-text {
  margin-top: 30px;
  margin-bottom: 20px;
}

.document-page-overview-file-upload {
  display: none;
}

.document-page-overview-processing-file {
  display: flex;
  align-items: center;
}

.document-page-overview-error {
  margin-top: 40px;
}
