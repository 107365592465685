.top-bar-barclays-tablet{

    margin-left: 80px;

}

.barclays-top-desktop{

    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;

}