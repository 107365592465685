.confirm-identity-body {
  display: flex;
  flex-direction: column;
}

/* Do not display logo when screen width is greater than 745px */
@media only screen and (min-width: 745px) {
  .confirm-identity-top-logo {
    display: none;
  }
}

.confirm-identity-input {
  width: 228px;
}

.confirm-identity-header {
  display: flex;
  margin-bottom: 39px;
  justify-content: space-between;
}

.confirm-identity-header h1 {
  font-weight: var(--fw-bold);
}

.confirm-identity-header__back-button {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.confirm-identity-header__back-button button {
  font-size: var(--fs-l);
  line-height: var(--lh-l);
}

.confirm-identity-header__back-button button:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.confirm-identity-expiry-text {
  font-weight: var(--fs-m);
  line-height: var(--lh-m);
  font-weight: var(--fw-regular);
  font-size: var(--fs-s);
  margin-bottom: 39px;
}

.confirm-identity-no-code-container {
  margin-top: 40px;
}

.confirm-identity-no-code-text {
  font-weight: var(--fw-medium);
  font-size: var(--fs-s);
}

.confirm-identity-no-code {
  margin-left: 10px;
  text-decoration: underline;
  font-size: var(--fs-s);
  color: var(--link-color-action-text-primary);
}

.confirm-identity-no-code:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.confirm-identity-divider {
  margin-top: 39px;
}

.confirm-identity-body .confirm-identity-button {
  width: 238px;
  color: var(--button-color-action-text-primary);
  background-color: var(--button-color-action-background-primary);
}
