@font-face {
  font-family: "Outfit";
  src: local("Outfit"),
    url(../Outfit/Outfit-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(../Outfit/Outfit-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Outfit";
  src: local("Outfit"),
    url(../Outfit/Outfit-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Outfit";
  src: local("Outfit"),
    url(../Outfit/Outfit-Bold.ttf) format("truetype");
  font-weight: 700;
}
