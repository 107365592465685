.display-document {
  &:focus {
    outline: 2px solid var(--color-input-outline-focus);
    outline-offset: 3px;
  }

  .display-document-container {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    touch-action: none; /* needed to stop drag and drop scrolling on mobile */

    .button svg {
      width: 27px;
      height: 27px;
    }

    .display-document-file-name {
      align-self: center;
      margin-left: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: 744px) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-break: break-all;
      }
    }

    .display-document-drag-and-drop {
      margin-left: auto;
      align-self: center;
      margin-right: 30px;
      @media (max-width: 744px) {
        margin-right: 0px;
      }
    }
  }
}

.display-document-is-deleting {
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  button.small {
    padding: 20px 11px;
    height: 50px;
  }

  .display-document-is-deleting-text {
    align-self: center;
    min-width: 330px;
  }

  .display-document-is-deleting-primary-button {
    margin-right: 20px;
  }
}

@media (max-width: 744px) {
  .display-document-container button {
    padding-left: 0px;
  }

  .display-document-is-deleting button.secondary {
    width: 92px;
  }
}
