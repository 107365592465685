.alert-message {
  position: relative;
  padding: var(--spacing-s) var(--spacing-m);
  margin-bottom: var(--spacing-l);
  border: 1px solid;
  border-radius: 0;
  border-width: 0px 0px 0px 4px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .alert-message {
    padding-right: 16px;
  }
}

.alert-message--info {
  background-color: var(--alert-message-color-feedback-background-info);
  border-color: var(--alert-message-color-feedback-border-info);
}

.alert-message--success {
  background-color: var(--alert-message-color-feedback-background-success);
  border-color: var(--alert-message-color-feedback-border-success);
}

.alert-message--warning {
  background-color: var(--alert-message-color-feedback-background-warning);
  border-color: var(--alert-message-color-feedback-border-warning);
}

.alert-message--error {
  background-color: var(--alert-message-color-feedback-background-error);
  border-color: var(--alert-message-color-feedback-border-error);
}

.alert-message--error a {
  line-height: var(--lh-xs);
}

.alert-message .alert-message__title {
  font-size: var(--fs-display);
  font-weight: var(--fw-bold);
  line-height: var(--lh-display);
}

.alert-message__description {
  display: flex;
  flex-direction: column;
}

.alert-message__description p:last-child {
  margin-bottom: 0;
}

.alert-message_loading-spinner {
  margin-top: 10px;
  margin-left: 100px;
}

.alert-message__icon {
  margin-right: var(--spacing-x-s);
}

.alert-message--has-no-left-thick-border {
  border-width: 0px 0px 0px 0px;
}

.alert-message--has-small-padding {
  padding: 16px;
}
