.menubarsimpleitem {
  font-weight: var(--fw-medium);
  font-size: var(--fs-s);
  color: var(--color-universal-secondary-b);
  border-radius: var(--border-radius-s);
  margin-right: 20px;
  padding: 5px 12px;

  &:hover {
    background-color: var(
      --component-color-interaction-background-primary-hover
    );
  }

  &:last-child {
    margin-right: 0;
  }
}
.quilter .menubarsimpleitem {
  text-transform: uppercase;
}

.menubarsimpleitem--active,
.menubarsimpleitem--active:hover {
  color: var(--navbar-color-interaction-background-primary);
  background-color: var(--navbar-color-interaction-background-active);
  border-radius: var(--border-radius-s);
}

.oneX-hub .menubarsimpleitem--active,
.oneX-hub .menubarsimpleitem--active {
  border-radius: 8px;
  padding: 5px 15px;
}
